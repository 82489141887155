import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import ActionPlanStandardSelection from '../../../../components/action_plan/customer/ActionPlanStandardSelection';
import ActionPlanActionsForm from './ActionPlanActionsForm';
import ActionPlanMaintainForm from './ActionPlanMaintainForm';
import Icon from 'components/Icon';

import { unselectTopic, saveTopic } from 'actions/action_plan/progress';

const ActionPlanTopicScreen = () => {
  const dispatch = useDispatch();
  const topic = useSelector((state) => state.form.currentTopicGoal);
  const saving = useSelector((state) => state.form.saving);
  const changed = useSelector((state) => state.form.changed);
  const editable = useSelector((state) => state.form.editingEnabled);
  const options = topic.assessment_topic.assessment_options.filter((option) => option.score >= topic.on_site_score);
  const selectedOptionId = useSelector((state) => state.form.selectedOption?.id);
  const selectedOption = options.find(option => option.id === selectedOptionId) || options[0];

  const handleBackClick = () => {
    dispatch(unselectTopic());
    window.scrollTo(0, 0);
  }

  return (
    <div className="actionPlanTopicScreen">
      <Button className="pull-right" bsStyle="default" onClick={handleBackClick}><Icon icon="arrow-left" /> Back</Button>
      <h1>{ topic.assessment_topic.name }</h1>
      <ActionPlanStandardSelection editable={editable} />

      {
        maintainingCurrent(topic, selectedOption) ? <ActionPlanMaintainForm editable={editable} /> : <ActionPlanActionsForm editable={editable} />
      }
      { (editable && changed) ?
        saving ?
            <Button className="pull-right margin-top-10" bsStyle="primary" disabled={true}><Icon icon="spinner" className="fa-pulse" /> Saving...</Button>
          :
            <Button className="pull-right margin-top-10" bsStyle="primary" onClick={() => dispatch(saveTopic())}><Icon icon="save" /> Save and update progress</Button>
        :
        <Button className="pull-right margin-top-10" bsStyle="default" onClick={handleBackClick}><Icon icon="arrow-left" /> Back</Button>
      }
  </div>
  )
}

function maintainingCurrent(goal, selectedOption) {
  return selectedOption.score === goal.on_site_score
}

export default ActionPlanTopicScreen;