import React from 'react';

import SentryErrorBoundary from 'components/SentryErrorBoundary';
import ProcessTotalsSection from './ProcessTotalsSection';

const WorkloadReportPage = ({ aggregated_time_taken, aggregated_all_time_taken, industrial_advisor, kpi_times }) => {
  const ia_selected = industrial_advisor ? true : false

  return (
    <SentryErrorBoundary>
      <div className="workload-report-wrapper">
        <ProcessTotalsSection aggregated_time_taken={aggregated_time_taken} aggregated_all_time_taken={aggregated_all_time_taken} ia_selected={ia_selected} kpi_times={kpi_times} />
      </div>
    </SentryErrorBoundary>
  )
}


export default WorkloadReportPage;
