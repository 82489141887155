import { saveInitialTopicGoalRequest } from 'services/api/action_plan/initial_api';
import { updateTopic, savingForm, savingComplete } from 'actions/action_plan/initial';

export const saveTopicGoalMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== 'CHANGE_TOPIC' && action.type !== 'SAVE_TOPIC') {
    return next(action);
  }

  const state = getState();

  if(state.form.changed) {
    dispatch(savingForm());

    saveInitialTopicGoalRequest(state.form.currentTopicGoal.id, payloadFromFormState(state.form))
    .then((res) => res.json())
    .then((topic) => {
      if(topic.status === 500) {
        throw new Error(topic.error);
      }
      if(topic.status >= 400) {
        throw new Error("Error saving form, please try again or contact support if the error persists");
      } else {
        dispatch(updateTopic(topic));
  
        if(action.type === 'SAVE_TOPIC') {
          return window.location.href = window.programmeHelper.linkHelper('/');
        } else {
          dispatch(savingComplete());
          return next(action);
        }
      }
    }).catch(err => {
      $.flashAlert(err.message, 'alert-danger');
      dispatch(savingComplete());
    });
  } else if(action.type === 'SAVE_TOPIC') {
    return window.location.href = window.programmeHelper.linkHelper('/');
  } else {
    return next(action);
  }
}

function payloadFromFormState(form) {
  return {
    action_plan_topic_goal: {
      assessment_option_id: form.selectedOption.id,
      no_action_reason: form.noActionReason,
      no_action: form.noAction,
      score: form.selectedOption.score,
      topic_goal_actions_attributes: form.actions.map((action) => {
        return {
          id: action.id,
          description: action.description,
          assigned_to: action.assigned_to,
          deadline: action.deadline,
          _destroy: action._destroy ? true : false
        }
      })
    }
  };
}