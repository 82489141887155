const defaultState = {};

export const ScoresReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'UPDATE_SCORES': {
      let newState = { ...state }
      newState[action.categoryId] = action.scores;
      return newState;
    }
  }

  return state;
};