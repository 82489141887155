import React from 'react';

import Icon from 'components/Icon';

export function calculateProgressPercent(actions) {
  if(actions.length === 0) {
    return 0;
  } else {
    return Math.round((actions.reduce((a,b) => a + b.completion_percentage, 0) / actions.length));
  }
}

const TopicStatus = ({topic}) => {
  if(calculateProgressPercent(topic.active_topic_goal_actions) === 100) {
    return <CompletedStatus />
  } else if(topic.score === topic.on_site_score || topic.no_action) {
    return <MaintainStatus />
  } else if(topic.score > topic.on_site_score && (topic.active_topic_goal_actions === undefined || topic.active_topic_goal_actions.length === 0)) {
    return <ActionRequiredStatus />
  } else if(numberOfOverdueActions(topic.active_topic_goal_actions) > 0) {
    return <ActionsOverdue count={numberOfOverdueActions(topic.active_topic_goal_actions)} />
  } else if(numberOfDueSoonActions(topic.active_topic_goal_actions) > 0) {
    return <ActionsDueSoon count={numberOfDueSoonActions(topic.active_topic_goal_actions)} />
  } else {
    return <InProgress count={numberOfActiveActions(topic.active_topic_goal_actions)} />
  }
}

const Status = ({ icon, text, className }) =>
  <div className={`status ${className}`}>
    { icon && <Icon icon={icon} /> }
    { text }
  </div>

const CompletedStatus = () => 
  <Status icon="check" text="Complete" className="complete" />

const MaintainStatus = () =>
  <Status text="Maintaining current" className="maintaining" />

const ActionRequiredStatus = () =>
  <Status icon="exclamation-triangle" text="Action Required" className="actionRequired" />

const ActionsOverdue = ({ count }) =>
  <Status icon="exclamation-triangle" text={`${count} Action${count > 1 ? 's' : ''} Overdue`} className="actionsOverdue" />

const ActionsDueSoon = ({ count }) =>
  <Status icon="exclamation-triangle" text={`${count} Action${count > 1 ? 's' : ''} Due soon`} className="actionsDueSoon" />

const InProgress = ({ count }) =>
  <Status text={`${count} Action${count === 1 ? '' : 's'} In Progress`} className="inProgress" />

function numberOfOverdueActions(actions) {
  let numberOverdue = 0;

  actions.forEach(action => {
    if(action['overdue?']) {
      numberOverdue += 1;
    }
  });

  return numberOverdue;
}

function numberOfDueSoonActions(actions) {
  let numberDueSoon = 0;

  actions.forEach(action => {
    if(action['due_soon?']) {
      numberDueSoon += 1;
    }
  });

  return numberDueSoon;
}

function numberOfActiveActions(actions) {
  return actions.filter((a) => a.completion_percentage !== 100).length;
}

export default TopicStatus;