const defaultState = {
  customActions: [],
  form: {
    description: '',
    assigned_to: '',
    deadline: '',
    completion_percentage: 0
  }
};

export const CustomActionsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'ADD_NEW_CUSTOM_ACTION': {
      const form = {
        type: 'new',
        description: '',
        assigned_to: '',
        deadline: '',
        completion_percentage: 0,
        action_document_logs: []
      }
      
      return { ...state, modalActive: true, saving: false, form };
    }
    case 'EDIT_CUSTOM_ACTION': {
      const form = {
        type: 'edit',
        id: action.customAction.id,
        description: action.customAction.description,
        assigned_to: action.customAction.assigned_to,
        deadline: new Date(action.customAction.deadline),
        completion_percentage: action.customAction.completion_percentage,
        action_document_logs: action.customAction.action_document_logs || [],
        progress: action.customAction.progress || ''
      }
      
      return { ...state, modalActive: true, saving: false, form };
    }
    case 'CANCEL_CUSTOM_ACTION_EDITING': {
      return { ...state, modalActive: false };
    }
    case 'UPDATE_CUSTOM_ACTION_FIELD': {
      let form = { ...state.form };
      form[action.name] = action.value;
      return { ...state, form };
    }
    case 'ADD_ERRORS_TO_CUSTOM_ACTION_FORM': {
      let form = { ...state.form };
      form.errors = action.errors;
      return { ...state, form };
    }
    case 'SAVING_FORM': {
      return { ...state, saving: true };
    }
    case 'SAVING_COMPLETE': {
      return { ...state, modalActive: false, saving: false };
    }
    case 'SAVING_COMPLETE_WITH_ERROR': {
      return { ...state, saving: false };
    }
    case 'CUSTOM_ACTION_ADDED': {
      const newActions = [ ...state.customActions ];
      newActions.push(action.customAction);
      return { ...state, customActions: newActions }
    }
    case 'CUSTOM_ACTION_UPDATED': {
      const newActions = [ ...state.customActions ];
      const updatedActionIndex = newActions.findIndex((customAction) => customAction.id === action.customAction.id);
      if(updatedActionIndex > -1) {
        newActions[updatedActionIndex] = action.customAction;
      }

      return { ...state, customActions: newActions }
    }
    case 'CUSTOM_ACTION_REMOVED': {
      const newActions = [ ...state.customActions ];

      const removedCustomActionIndex = newActions.findIndex((customAction) => customAction.id === action.customActionId);
      if(removedCustomActionIndex > -1) {
        newActions.splice(removedCustomActionIndex, 1);
      }

      return { ...state, customActions: newActions }
    }
  }

  return state;
};