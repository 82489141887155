import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';

import { closeModal } from 'actions/reports/workload';

import ModalContentLoading from './ModalContentLoading';
import ModalContentSwitcher from './ModalContentSwitcher';

const CompaniesModal = () => {
  const dispatch = useDispatch();

  const showModal = useSelector((state) => state.workload.showModal);
  const loadingData = useSelector((state) => state.workload.loadingData);
  const companies = useSelector((state) => state.workload.companies_data);
  const modalHeading = useSelector((state) => state.workload.heading);
  const section = useSelector((state) => state.workload.section);
  const row = useSelector((state) => state.workload.row);
  const company_record_ids = useSelector((state) => state.workload.company_record_ids);

  const handleCloseModal = () => {
    dispatch(closeModal());
  }

  let content = undefined;

  if(loadingData) {
    content = <ModalContentLoading />
  } else if(companies) {
    content = <ModalContentSwitcher companies={companies} section={section} row={row} company_record_ids={company_record_ids} />
  }

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{ modalHeading } </Modal.Title>
      </Modal.Header>
      { content }
      <Modal.Footer>
        <Button onClick={handleCloseModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CompaniesModal;