import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import ActionPlanStandardSelection from '../../../../components/action_plan/customer/ActionPlanStandardSelection';
import ActionPlanTopicActions from './ActionPlanTopicActions';
import Icon from 'components/Icon';
import { saveTopic, savingForm } from 'actions/action_plan/initial/form.actions';

const ActionPlanInitialForm = () => {
  const dispatch = useDispatch();
  const assessmentTopic = useSelector((state) => state.form.currentTopicGoal.assessment_topic);
  const selectedOption = useSelector((state) => state.form.selectedOption);
  const noAction = useSelector((state) => state.form.noAction);
  const saving = useSelector((state) => state.form.saving);

  const handleSaveClick = () => {
    dispatch(savingForm());
    dispatch(saveTopic());
  }

  return (
    <div className="actionPlanInitialForm">
      <FormSaveButton saving={saving} handleSaveClick={handleSaveClick} />
      <h1 className="margin-bottom-0"><small>{ assessmentTopic.assessment_category.name }</small></h1>
      <h1 className="margin-bottom-5">{ assessmentTopic.name }</h1>

      <ActionPlanStandardSelection />
      { (selectedOption || noAction) && 
        <div className="actions">
          <ActionPlanTopicActions />
        </div>
      }
    </div>
  )
}

const FormSaveButton = ({ saving, handleSaveClick }) => {
  if(saving) {
    return (
      <Button disabled={true} bsStyle="primary" className="pull-right">
        <Icon icon="spinner" className="fa-pulse" /> Saving...
      </Button>
    );
  } else {
    return (
      <Button onClick={handleSaveClick} bsStyle="primary" className="pull-right">
        <Icon icon="save" /> Save and finish
      </Button>
    );
  }
}

export default ActionPlanInitialForm;