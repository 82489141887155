const defaultState = {
  showModal: false,
  loadingData: false
}

export const WorkloadReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      let newState = { ...state };
      newState.showModal = true;
      newState.loadingData = true;
      newState.company_record_ids = action.company_record_ids;
      newState.heading = action.heading;
      newState.section = action.section;
      newState.row = action.row;
      return newState;
    }
    case 'CLOSE_MODAL': {
      return {
        showModal: false,
        loadingData: false,
        company_record_ids: []
      };
    }
    case 'LOAD_COMPANIES_SUCCESS': {
      let newState = { ...state }
      newState.companies_data = action.companies_data;
      newState.loadingData = false
      return newState;
    }
    case 'LOAD_COMPANIES_ERROR': {
      let newState = { ...state }
      newState.error = 'Failed to get the company data';
      return newState;
    }
    case 'COMPLETED_WORKLOAD_LOAD': {
      let newState = { ...state }
      newState.company_record_ids = {};
      newState.company_record_ids.company_record_ids = state.company_record_ids;
      newState.company_record_ids.completed_companies_id = action.completed_companies_id;
      return newState;
    }
  }

  return state;
}
