export const checkPresence = (value) => {
  if (value === undefined || value === '' || value === null) {
    return 'Must have a value';
  }
  return undefined;
};

export const checkBetweenLengths = (value, min, max) => {
  if (value.length < min) {
    return `Must be longer than ${min} characters`;
  }
  if (value.length > max) {
    return `Must be shorter than ${max} characters`;
  }
  return undefined;
};

export const checkLengthLessThan = (value, min) => {
  if (value.length > min) {
    return `Must be longer than ${min} characters`;
  }
  return undefined;
};

export const dateAfterDate = (value, afterDate) => {
  if(value < afterDate) {
    return `Must be on or after ${afterDate.toDateString()}`;
  }

  return undefined;
}

export function validate(values, validators) {
  const errors = {};

  Object.entries(validators).forEach(([key, validatorArray]) => {
    validatorArray.some((validator) => {
      const error = validator(values[key]);
      if (error) {
        errors[key] = error;
        return true;
      }
      return false;
    });
  });

  Object.keys(errors).forEach((key) => (errors[key] === undefined ? delete errors[key] : {}));
  return errors;
}
