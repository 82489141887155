import I18n from 'locales/index.js'

const default_programme_process_totals_stages = {
  time_to_capability_questionnaire_complete: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_complete.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_complete.help_text'),
    to_scale: true,
    kpi_able: false
  }
}

const development_assessment_process_totals_stages = {
  time_to_capability_questionnaire_complete: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_complete.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_complete.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_capability_questionnaire_reviewed: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_reviewed.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'ncq_referred'
  },
  time_to_self_diagnostic_complete: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_complete.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_complete.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_self_diagnostic_reviewed: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_reviewed.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'online_assessment_referred'
  },
  time_to_on_sites_booked: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_booked.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_booked.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'pending_on_site_assessment'
  },
  time_to_on_sites_attended: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_attended.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_attended.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_first_on_site_draft_submitted: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_first_on_site_draft_submitted.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_first_on_site_draft_submitted.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'on_site_assessment_report_required'
  },
  time_to_on_site_drafts_accepted: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_drafts_accepted.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_drafts_accepted.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_on_site_attended_to_accepted: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_attended_to_accepted.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_attended_to_accepted.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_first_action_plan_submission: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_first_action_plan_submission.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_first_action_plan_submission.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_action_plan_feedback: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_action_plan_feedback.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_action_plan_feedback.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'action_plan_submitted'
  },
  time_to_recommended_for_granting_after_initial_action_plan: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_recommended_for_granting_after_initial_action_plan.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_recommended_for_granting_after_initial_action_plan.help_text'),
    to_scale: false,
    kpi_able: false
  },
  time_to_f4n_granting_after_recommendation: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_f4n_granting_after_recommendation.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_f4n_granting_after_recommendation.help_text'),
    to_scale: true,
    kpi_able: false
  },
  total_time_for_f4n_granting: {
    name: I18n.t('staff.reports.workload_report.process_totals.total_time_for_f4n_granting.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.total_time_for_f4n_granting.help_text'),
    to_scale: false,
    kpi_able: false
  }
}


let process_totals_stages_value;
if (window.programmeHelper.isDefaultProgramme()) {
  process_totals_stages_value = {...default_programme_process_totals_stages, ...development_assessment_process_totals_stages} 
} else {
  process_totals_stages_value = development_assessment_process_totals_stages
}

export const process_totals_stages = process_totals_stages_value
