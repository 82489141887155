const defaultState = {
  currentTopicGoal: undefined,
  selectedOption: undefined,
  selectedIndex: 0,
  actions: [],
  noActionReason: '',
  noAction: undefined,
  changed: true,
  saving: false,
  actionForm: {
    description: '',
    assigned_to: '',
    deadline: ''
  }
};

export const FormReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'SELECT_ASSESSMENT_GOAL': {
      let newState = { ...state }
      newState.selectedOption = newState.currentTopicGoal.assessment_topic.assessment_options.find((option) => option.id == action.optionId);
      newState.changed = true;
      newState.noAction = newState.selectedOption.score === newState.currentTopicGoal.on_site_score;
      return newState;
    }
    case 'REMOVE_ACTION': {
      let newState = { ...state };
      let newActions = [ ...newState.actions ];
      let filteredActions = filterActions(newActions);

      filteredActions[action.actionIndex].removed = true;
      newState.changed = true;

      return {
        ...newState,
        actions: newActions
      };
    }
    case 'UPDATE_ACTION': {
      let newState = { ...state };
      let newActions = [ ...newState.actions ];
      let filteredActions = newActions.filter((action) => action.removed === undefined || action.remove === false);

      filteredActions[action.payload.actionIndex][action.payload.field] = action.payload.value;
      filteredActions[action.payload.actionIndex].changed = true;
      newState.changed = true;

      return {
        ...newState,
        actions: newActions
      };
    }
    case 'CHANGE_TOPIC': {
      let newState = { ...state };

      newState.currentTopicGoal = { ...action.topic };
      newState.actions = JSON.parse(JSON.stringify(newState.currentTopicGoal.active_topic_goal_actions));
      newState.actions.forEach((a) => {
        a.previousDocuments = a.action_document_logs;
      });

      if(newState.currentTopicGoal.assessment_option_id === undefined || newState.currentTopicGoal.assessment_option_id === null) {
        newState.selectedOption = newState.currentTopicGoal.assessment_topic.assessment_options.find((option) => option.score === newState.currentTopicGoal.on_site_score);
      } else {
        newState.selectedOption = newState.currentTopicGoal.assessment_topic.assessment_options.find((option) => option.id === newState.currentTopicGoal.assessment_option_id);
      }

      newState.changed = false;
      newState.noActionReason = newState.currentTopicGoal.no_action_reason;
      newState.noAction = newState.selectedOption ? newState.selectedOption.score === newState.currentTopicGoal.on_site_score : false;
      newState.selectedIndex = action.index;

      return newState;
    }
    case 'UPDATE_NO_ACTION_REASON': {
      let newState = { ...state };
      newState.noActionReason = action.noActionReason;
      newState.changed = true;
      return newState;
    }
    case 'SAVING_FORM': {
      let newState = { ...state };
      newState.saving = true;
      return newState;
    }
    case 'SAVING_COMPLETE': {
      let newState = { ...state };
      newState.saving = false;
      return newState;
    }
    case 'SAVING_COMPLETE_WITH_ERROR': {
      return { ...state, saving: false };
    }
    case 'UNSELECT_TOPIC': {
      let newState = { ...state };
      newState.currentTopicGoal = undefined;
      return newState;
    }
    case 'ACTION_FORM_CREATE': {
      if(action.actionIndex !== undefined) {
        const filteredActions = filterActions(state.actions);

        return {
          ...state,
          actionForm: {
            description: filteredActions[action.actionIndex].description,
            assigned_to: filteredActions[action.actionIndex].assigned_to,
            deadline: new Date(filteredActions[action.actionIndex].deadline),
            errors: undefined
          }
        }
      } else {
        return {
          ...state,
          actionForm: {
            description: '',
            assigned_to: '',
            deadline: '',
            errors: undefined
          }
        }
      }
    }
    case 'EDIT_ACTION': {
      let actionForm = { ...state.actionForm };

      actionForm[action.field] = action.value;

      return {
        ...state,
        actionForm
      };
    }
    case 'SAVE_ACTION': {
      let newActions = [ ...state.actions ];
      let filteredActions = filterActions(newActions);
      let actionToUpdate = filteredActions[action.actionIndex];

      actionToUpdate.description = state.actionForm.description;
      actionToUpdate.assigned_to = state.actionForm.assigned_to;
      actionToUpdate.deadline = state.actionForm.deadline;
      actionToUpdate.changed = true;

      return {
        ...state,
        actions: newActions,
        changed: true
      };
    }
    case 'ADD_ACTION': {
      let newState = { ...state };
      let newActions = [ ...newState.actions ];

      newActions.push({
        description: newState.actionForm.description,
        assigned_to: newState.actionForm.assigned_to,
        deadline: newState.actionForm.deadline,
        temp_id: Date.now(),
        comment: '',
        completion_percentage: 0,
        action_document_logs: [],
        changed: true
      });
      newState.changed = true;

      return {
        ...newState,
        actions: newActions
      };
    }
    case 'ADD_ERRORS_TO_ACTION': {
      let newState = { ...state };
      let newActions = [ ...newState.actions ];

      newActions[action.actionIndex].errors = action.errors;

      return {
        ...newState,
        actions: newActions
      };
    }
    case 'ADD_ERRORS_TO_ACTION_FORM': {
      let newState = { ...state };

      newState.actionForm.errors = action.errors;

      return newState;
    }
    case 'UPDATE_SCROLL_POSITION': {
      let newState = { ...state };
      newState.scrollY = action.scrollY;
      return newState;
    }
  }

  return state;
};

function filterActions(actions) {
  return actions.filter((action) => action.removed === undefined || action.remove === false)
}