import React from 'react';
import ProcessTotalsGraph from './ProcessTotalsGraph';
import ProcessTotalsTable from './ProcessTotalsTable';

export default class ProcessTotalsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      report_type: 'total',
      show_process_average: false,
      show_kpis: false
    }
  }

  handleReportTypeChange = (e) => {
    this.setState({ report_type: e.target.value })
  }

  handleProcessAverageChange = (e) => {
    this.setState({ show_process_average: e.target.checked })
  }

  handleKpisChange = (e) => {
    this.setState({ show_kpis: e.target.checked })
  }

  render() {
    const { aggregated_time_taken, aggregated_all_time_taken, ia_selected, kpi_times } = this.props;
    const { report_type, show_process_average, show_kpis } = this.state;

    return (
      <div className="process-totals">
        <div className="row">
          <div className="col-md-8"><h1>Process timescales</h1></div>
        </div>
        
        <div className="row">
          { ia_selected &&
            <div className="col-lg-3 col-lg-offset-7">
              <div className="checkbox">
                <label>
                  <input
                    name="showProcessAverage"
                    type="checkbox"
                    checked={show_process_average}
                    onChange={this.handleProcessAverageChange} />
                    Show all IA’s process timescales
                </label>
              </div>
            </div>
          }
          <div className={`col-lg-2 text-right ${!ia_selected ? 'col-lg-offset-10' : ''}`}>
            <div className="checkbox">
              <label>
                <input
                  name="showProcessAverage"
                  type="checkbox"
                  checked={show_kpis}
                  onChange={this.handleKpisChange} />
                  Show KPIs
              </label>
            </div>
          </div>
        </div>
        <ProcessTotalsGraph aggregated_time_taken={aggregated_time_taken} report_type={report_type} aggregated_all_time_taken={aggregated_all_time_taken} show_process_average={show_process_average} show_kpis={show_kpis} kpi_times={kpi_times} />
        <ProcessTotalsTable aggregated_time_taken={aggregated_time_taken} report_type={report_type} aggregated_all_time_taken={aggregated_all_time_taken} show_process_average={show_process_average} show_kpis={show_kpis} kpi_times={kpi_times} />
      </div>
    )
  }
}