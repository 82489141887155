import { postRequest, patchRequest, deleteRequest } from '../base_requests';

export function createChecklistActionRequest(actionPlanId, body) {
  return postRequest({ url: `/customer/api/action_plan/${actionPlanId}/checklist_actions`, body, asJson: false });
}

export function updateChecklistActionRequest(actionPlanId, checklistActionId, body) {
  return patchRequest({ url: `/customer/api/action_plan/${actionPlanId}/checklist_actions/${checklistActionId}`, body, asJson: false });
}

export function deleteChecklistActionRequest(actionPlanId, checklistActionId) {
  return deleteRequest({ url: `/customer/api/action_plan/${actionPlanId}/checklist_actions/${checklistActionId}` });
}