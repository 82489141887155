import React from 'react';
import CompletedWorkloadGraph from './CompletedWorkloadGraph';
import CompletedWorkloadTable from './CompletedWorkloadTable';

class CompletedWorkload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStages: ['action_plan_feedback_given']
    }
  }

  handleStageClick = (stage) => {
    let { selectedStages } = this.state;

    if(selectedStages.includes(stage)) {
      selectedStages.splice(selectedStages.indexOf(stage), 1)
    } else {
      selectedStages.push(stage);
    }
    
    this.setState({ selectedStages })
  }

  render() {
    const { aggregated_completed_workload } = this.props;
    const { selectedStages } = this.state;

    return (
      <>
        <h1>Completed Workload</h1>
        <CompletedWorkloadGraph data={aggregated_completed_workload} selectedStages={selectedStages} />
        <CompletedWorkloadTable data={aggregated_completed_workload} selectedStages={selectedStages} handleStageClick={this.handleStageClick}/>
      </>
    )
  }
}

export default CompletedWorkload;