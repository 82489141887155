import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Collapse, Button } from 'react-bootstrap';

import { completed_workload_stage_names } from 'services/completed_workload_helper';

const CompletedWorkloadGraph = ({ data, selectedStages }) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="complete-workload-graph-wrapper">
      <Button bsSize="small" className="hide-graph-button" onClick={() => setOpen(!open)}>{ open && 'Hide'}{ !open && 'Show'} graph</Button>
      <Collapse in={open}>
        <div className="complete-workload-graph">
          <ResponsiveLine
            data={getData(data, selectedStages)}
            margin={{ top: 20, right: 40, bottom: 30, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            colors={d => d.color}
            lineWidth={5}
            pointSize={12}
            pointColor="white"
            pointBorderWidth={3}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={({ point }) => {
              return (
                <div style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc'}}>
                  <div style={{
                    borderLeft: `4px solid ${point.serieColor}`,
                    padding: '3px 0 3px 4px' }}><strong>{point.serieId}</strong></div>
                  <div>{point.data.xFormatted}: {point.data.yFormatted}</div>
                </div>
              )
            }} />
          { selectedStages.length === 0 && 
            <div className="complete-workload-graph-empty">
              <p>Please choose options from the table below to populate the graph with data.</p>
            </div>
          }
        </div>
      </Collapse>
    </div>
  )
}

function stageToPoints(data, stage) {
  return data.map((dataRow) => { return { x: dataRow.date, y: dataRow[stage].count } })
}

function dataForStage(data, stage) {
  const stageData = completed_workload_stage_names[stage];

  return {
    id: stageData.name,
    color: stageData.color,
    data: stageToPoints(data, stage)
  }
}

function getData(data, selectedStages) {
  return selectedStages.
    map(stage => [
      null,
      dataForStage(data, stage)
    ]).flat().filter(Boolean);
}

export default CompletedWorkloadGraph;