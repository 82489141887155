export function actionPlanProgressCount(data) {
  const totalCount = data.length;
  const totalComplete = data.filter(topic => validateTopic(topic)).length

  return `${totalComplete}/${totalCount}`;
}

export function validateTopic(topic) {
  if(topic.active_topic_goal_actions.length > 0) {
    return Object.values(topic.active_topic_goal_actions).every(action => action.description && action.assigned_to && action.deadline)
  } else if(topic.no_action && topic.no_action_reason) {
    return true;
  }
  return false;
}