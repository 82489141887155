import React from "react"

import App from "components/App"
import MenuContainer from "./MenuContainer"

import "styles/menu/Menu.scss"

const MenuIndex = ({ menuJson, initialSelected }) => {
  initialSelected = initialSelected || [null, null, null]
  
  return (
    <App>
      <React.StrictMode>
        <MenuContainer menuJson={menuJson} initialSelected={initialSelected} />      
      </React.StrictMode>
    </App>
  )
}

export default MenuIndex
