const defaultState = {
  currentTopicGoal: undefined,
  selectedOption: undefined,
  selectedIndex: 0,
  actions: [],
  noActionReason: '',
  noAction: undefined,
  changed: true,
  saving: false,
};

function ifNotSaving(state, next) {
  if(state.saving === false) {
    return next();
  } else {
    return state;
  }
}

export const FormReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'SELECT_ASSESSMENT_GOAL': {
      return ifNotSaving(state, () => {
        let newState = { ...state }
        newState.selectedOption = newState.currentTopicGoal.assessment_topic.assessment_options.find((option) => option.id == action.optionId);
        newState.changed = true;
        newState.noAction = newState.selectedOption.score === newState.currentTopicGoal.on_site_score;
        return newState;
      });
    }
    case 'ADD_ACTION': {
      return ifNotSaving(state, () => {
        let newState = { ...state };
        let newActions = [ ...newState.actions ];
        
        newActions.push({
          description: '',
          assigned_to: '',
          deadline: '',
          temp_id: Date.now()
        });
        newState.changed = true;
  
        return {
          ...newState,
          actions: newActions
        };
      });
    }
    case 'REMOVE_ACTION': {
      return ifNotSaving(state, () => {
        let newState = { ...state };
        let newActions = [ ...newState.actions ];
        let filteredActions = newActions.filter((action) => action._destroy === undefined);

        filteredActions[action.actionIndex]._destroy = true;
        newState.changed = true;

        return {
          ...newState,
          actions: newActions
        };
      });
    }
    case 'UPDATE_ACTION': {
      return ifNotSaving(state, () => {
        let newState = { ...state };
        let newActions = [ ...newState.actions ];
        let filteredActions = newActions.filter((action) => action._destroy === undefined);

        filteredActions[action.payload.actionIndex][action.payload.field] = action.payload.value
        newState.changed = true;

        return {
          ...newState,
          actions: newActions
        };
      });
    }
    case 'CHANGE_TOPIC': {
      return ifNotSaving(state, () => {
        let newState = { ...state };

        newState.currentTopicGoal = action.topic;
        newState.actions = action.topic.active_topic_goal_actions;
        newState.selectedOption = action.topic.assessment_topic.assessment_options.find((option) => option.id === action.topic.assessment_option_id);
        newState.changed = false;
        newState.noActionReason = action.topic.no_action_reason;
        newState.noAction = newState.selectedOption ? newState.selectedOption.score === newState.currentTopicGoal.on_site_score : newState.currentTopicGoal.no_action;
        newState.selectedIndex = action.index;

        return newState;
      });
    }
    case 'UPDATE_NO_ACTION_REASON': {
      return ifNotSaving(state, () => {
        let newState = { ...state };
        newState.noActionReason = action.noActionReason;
        newState.changed = true;
        return newState;
      });
    }
    case 'SAVING_FORM': {
      let newState = { ...state };
      newState.saving = true;
      return newState;
    }
    case 'SAVING_COMPLETE': {
      let newState = { ...state };
      newState.saving = false;
      return newState;
    }
  }

  return state;
};