import { loadCompaniesSuccess, loadCompaniesError, completedWorkloadLoad } from 'actions/reports/workload';
import { requestCompaniesDetails } from 'services/api/reports/workload.api';

export const getCompletedWorkloadCompanyRecords = ({ dispatch, getState }) => next => action => {
  if(action.type !== "OPEN_MODAL" || action.section !== 'completed_workload') {
    return next(action)
  } else if(action.company_record_ids.length === 0) {
    return null
  }

  dispatch(completedWorkloadLoad(action.company_record_ids))

  requestCompaniesDetails(action.company_record_ids)
    .then(res => res.json())
    .then(res => dispatch(loadCompaniesSuccess(res)))
    .catch(err => dispatch(loadCompaniesError(err)));
  
  next(action);
};
