import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';

import { updateNoActionText } from 'actions/action_plan/initial';

const ActionPlanMaintainForm = ({ editable }) => {
  const dispatch = useDispatch();
  const noActionReason = useSelector((state) => state.form.noActionReason);

  return (
    <div className="actionPlanMaintainForm margin-top-20">
      <FormGroup
        controlId="no_action_reason">
        <ControlLabel>Reason for maintaining current score</ControlLabel>
        { editable ? 
          <>
            <FormControl
              name="no_action_reason"
              componentClass="textarea"
              value={noActionReason}
              onChange={(e) => dispatch(updateNoActionText(e.target.value))}
              rows="4" />
            <HelpBlock>Maximum 200 characters</HelpBlock>
          </>
          :
          <p className="lead">{ noActionReason }</p>
        }
      </FormGroup>
    </div>
  )
}

export default ActionPlanMaintainForm;