import React from "react"

import clsx from "clsx"

const MenuItem = ({
  id,
  title,
  href,
  icon,
  iconStyle,
  arrow,
  selected,
  nested,
  children
}) => {
  const contents = (
    <>
      {icon && <i className={clsx("item-icon", iconStyle || "fas", `fa-${icon}`)} />}
      <span>{title}</span>
      {arrow && <i className="item-arrow fas fa-chevron-right" />}
    </>
  )

  const isSelected = selected && selected[nested] == id 

  return (
    <li
      className={clsx(isSelected && "selected", nested && "nested")}
      data-id={id}
      data-nested={nested}
    >
      {href ? (
        <a href={href}>{contents}</a>
      ) : (
        <button type="button">{contents}</button>
      )}
      {children}
    </li>
  )
}

export default MenuItem
