import { deleteChecklistActionRequest } from 'services/api/action_plan/checklist_actions';
import { savingForm, savingComplete, savingCompleteWithError, checklistActionRemoved } from 'actions/action_plan/progress';


export const removeChecklistActionMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== 'REMOVE_CHECKLIST_ACTION') {
    return next(action);
  }

  const state = getState();

  dispatch(savingForm());
  deleteChecklistActionRequest(state.actionPlan.id, action.checklistActionId)
    .then((res) => {
      if(res.status >= 400) {
        throw new Error("Error removing checklist action, please try again or contact support if the error persists");
      } else {
        return res;
      }
    })
    .then(() => {
      dispatch(checklistActionRemoved(action.categoryId, action.checklistItemId, action.checklistActionId));
      dispatch(savingComplete());
      $.flashAlert('Checklist action removed.', 'alert-success');
    })
    .catch(err => {
      $.flashAlert(err.message, 'alert-danger');
      dispatch(savingCompleteWithError());
    });
}