export const selectAssessmentGoal = (topicId, optionId) => ({
  type: 'SELECT_ASSESSMENT_GOAL',
  topicId,
  optionId
});

export const addAction = () => ({
  type: 'ADD_ACTION'
});

export const updateAction = (actionIndex, field, new_value) => ({
  type: 'UPDATE_ACTION',
  payload: {
    actionIndex,
    field,
    value: new_value
  }
});

export const removeAction = (actionIndex) => ({
  type: 'REMOVE_ACTION',
  actionIndex
});

export const changeTopic = (topic, index) => ({
  type: 'CHANGE_TOPIC',
  topic,
  index
});

export const savingForm = () => ({
  type: 'SAVING_FORM'
});

export const savingComplete = () => ({
  type: 'SAVING_COMPLETE'
});

export const savingCompleteWithError = () => ({
  type: 'SAVING_COMPLETE_WITH_ERROR'
})

export const updateNoActionText = (noActionReason) => ({
  type: 'UPDATE_NO_ACTION_REASON',
  noActionReason
});

export const unselectTopic = () => ({
  type: 'UNSELECT_TOPIC'
});

export const saveTopic = () => ({
  type: 'SAVE_TOPIC'
});

export const saveAction = (actionIndex) => ({
  type: 'SAVE_ACTION',
  actionIndex
});

export const editAction = (field, value) => ({
  type: 'EDIT_ACTION',
  field,
  value
});

export const actionFormCreate = (actionIndex) => ({
  type: 'ACTION_FORM_CREATE',
  actionIndex
});

export const addErrorsToAction = (errors, actionIndex) => ({
  type: 'ADD_ERRORS_TO_ACTION',
  errors,
  actionIndex
});

export const addErrorsToActionForm = (errors) => ({
  type: 'ADD_ERRORS_TO_ACTION_FORM',
  errors
});

export const updateScrollPosition = (scrollY) => ({
  type: 'UPDATE_SCROLL_POSITION',
  scrollY
})