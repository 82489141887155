import { deleteCustomActionRequest } from 'services/api/action_plan/custom_actions';
import { savingForm, savingComplete, savingCompleteWithError, customActionRemoved } from 'actions/action_plan/progress';


export const removeCustomActionMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== 'REMOVE_CUSTOM_ACTION') {
    return next(action);
  }

  const state = getState();
  dispatch(savingForm());
  deleteCustomActionRequest(state.actionPlan.id, action.customActionId)
    .then((res) => {
      if(res.status >= 400) {
        throw new Error("Error removing custom action, please try again or contact support if the error persists");
      } else {
        return res;
      }
    })
    .then(() => {
      dispatch(customActionRemoved(action.customActionId));
      dispatch(savingComplete());
      $.flashAlert('Custom action removed.', 'alert-success');
    })
    .catch(err => {
      $.flashAlert(err.message, 'alert-danger');
      dispatch(savingCompleteWithError());
    });
}