export const addNewChecklistAction = (categoryId, checklistItemId) => ({
  type: 'ADD_NEW_CHECKLIST_ACTION',
  categoryId,
  checklistItemId
});

export const editChecklistAction = (categoryId, checklistAction) => ({
  type: 'EDIT_CHECKLIST_ACTION',
  categoryId,
  checklistAction
});

export const saveChecklistAction = () => ({
  type: 'SAVE_CHECKLIST_ACTION'
});

export const removeChecklistAction = (categoryId, checklistItemId, checklistActionId) => ({
  type: 'REMOVE_CHECKLIST_ACTION',
  categoryId,
  checklistItemId,
  checklistActionId
})

export const cancelChecklistActionEditing = () => ({
  type: 'CANCEL_CHECKLIST_ACTION_EDITING'
});

export const updateChecklistActionField = (name, value) => ({
  type: 'UPDATE_CHECKLIST_ACTION_FIELD',
  name,
  value
});

export const addErrorsToChecklistActionForm = (errors) => ({
  type: 'ADD_ERRORS_TO_CHECKLIST_ACTION_FORM',
  errors
});

export const checklistActionAdded = (categoryId, checklistAction) => ({
  type: 'CHECKLIST_ACTION_ADDED',
  categoryId,
  checklistAction
});

export const checklistActionUpdated = (categoryId, checklistAction) => ({
  type: 'CHECKLIST_ACTION_UPDATED',
  categoryId,
  checklistAction
});

export const checklistActionRemoved = (categoryId, checklistItemId, checklistActionId) => ({
  type: 'CHECKLIST_ACTION_REMOVED',
  categoryId,
  checklistItemId,
  checklistActionId
});