import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { editAction } from 'actions/action_plan/progress';

const ActionEditForm = () => {
  const dispatch = useDispatch();
  const action = useSelector((state) => state.form.actionForm);
  const errors = useSelector((state) => state.form.actionForm.errors || {});

  const handleActionUpdate = (field, value) => {
    dispatch(editAction(field, value));
  }

  return (
    <Row className="actionForm">
      <Col md={7}>
        <FormGroup controlId="description" validationState={errors.description ? 'error' : null}>
          <ControlLabel>Action to be undertaken</ControlLabel>
          <FormControl
            componentClass="textarea"
            type="textarea"
            name="description"
            value={action.description}
            rows="4"
            maxLength="200"
            onChange={(e) => handleActionUpdate(e.target.name, e.target.value)} />
          <HelpBlock>Maximum 200 characters{ errors.description && ` - ${errors.description}` }</HelpBlock>
        </FormGroup>
      </Col>
      <Col md={5}>
        <FormGroup controlId="assigned_to" validationState={errors.assigned_to ? 'error' : null}>
          <ControlLabel>Action owner</ControlLabel>
          <FormControl
            type="text"
            name="assigned_to"
            value={action.assigned_to}
            onChange={(e) => handleActionUpdate(e.target.name, e.target.value)} />
          { errors.assigned_to && <HelpBlock>{errors.assigned_to}</HelpBlock> }
        </FormGroup>
        <FormGroup controlId="deadline" validationState={errors.deadline ? 'error' : null}>
          <ControlLabel>Completion target date</ControlLabel>
          <DatePicker
            selected={action.deadline}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => handleActionUpdate('deadline', date)}
            className='form-control' />
          { errors.deadline && <HelpBlock>{errors.deadline}</HelpBlock> }
        </FormGroup>
      </Col>
    </Row>
  );
}

export default ActionEditForm;
