const defaultState = {
  checklistItems: [],
  form: {
    description: '',
    assigned_to: '',
    deadline: '',
    completion_percentage: 0,
    checklist_item_id: undefined
  }
};

export const ChecklistItemsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'ADD_NEW_CHECKLIST_ACTION': {
      const form = {
        type: 'new',
        description: '',
        assigned_to: '',
        deadline: '',
        completion_percentage: 0,
        checklist_item_id: action.checklistItemId,
        category_id: action.categoryId,
        action_document_logs: []
      }

      return { ...state, modalActive: true, saving: false, form };
    }
    case 'EDIT_CHECKLIST_ACTION': {
      const form = {
        type: 'edit',
        id: action.checklistAction.id,
        description: action.checklistAction.description,
        assigned_to: action.checklistAction.assigned_to,
        deadline: new Date(action.checklistAction.deadline),
        completion_percentage: action.checklistAction.completion_percentage,
        checklist_item_id: action.checklistAction.checklist_item_id,
        progress: action.checklistAction.progress || '',
        category_id: action.categoryId,
        action_document_logs: action.checklistAction.action_document_logs || []
      }
      
      return { ...state, modalActive: true, saving: false, form };
    }
    case 'CANCEL_CHECKLIST_ACTION_EDITING': {
      return { ...state, modalActive: false };
    }
    case 'UPDATE_CHECKLIST_ACTION_FIELD': {
      let form = { ...state.form };
      form[action.name] = action.value;
      return { ...state, form };
    }
    case 'ADD_ERRORS_TO_CHECKLIST_ACTION_FORM': {
      let form = { ...state.form };
      form.errors = action.errors;
      return { ...state, form };
    }
    case 'SAVING_FORM': {
      return { ...state, saving: true };
    }
    case 'SAVING_COMPLETE_WITH_ERROR': {
      return { ...state, saving: false };
    }
    case 'SAVING_COMPLETE': {
      return { ...state, modalActive: false, saving: false };
    }
    case 'CHECKLIST_ACTION_ADDED': {
      const updatedChecklistItems = {...state.items };
      updatedChecklistItems[action.categoryId].items[action.checklistAction.checklist_item_id].actions.push(action.checklistAction)
      return { ...state, checklistItems: updatedChecklistItems }
    }
    case 'CHECKLIST_ACTION_UPDATED': {
      const updatedChecklistItems = {...state.items };
      
      const updatedActionIndex = updatedChecklistItems[action.categoryId].items[action.checklistAction.checklist_item_id].actions.findIndex((checklistAction) => checklistAction.id === action.checklistAction.id);
      if(updatedActionIndex > -1) {
        updatedChecklistItems[action.categoryId].items[action.checklistAction.checklist_item_id].actions[updatedActionIndex] = action.checklistAction;
      }

      return { ...state, checklistItems: updatedChecklistItems }
    }
    case 'CHECKLIST_ACTION_REMOVED': {
      const updatedChecklistItems = {...state.items };
      const removedChecklistItemIndex = updatedChecklistItems[action.categoryId].items[action.checklistItemId].actions.findIndex((checklistAction) => checklistAction.id === action.checklistActionId);

      if(removedChecklistItemIndex > -1) {
        updatedChecklistItems[action.categoryId].items[action.checklistItemId].actions.splice(removedChecklistItemIndex, 1);
      }

      return { ...state, checklistItems: updatedChecklistItems }
    }
  }

  return state;
};