const defaultState = [];

export const TopicsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'UPDATE_TOPIC': {
      let newState = [ ...state ];
      newState[newState.findIndex((topic) => topic.id === action.topic.id)] = action.topic;
      return newState;
    }
  }

  return state;
};