import { getCsrfToken } from './csrf_helper';

export function getRequest({ url, params }) {
  const urlWithCurrentProgramme = window.programmeHelper.linkHelper(url);
  return fetch(`${urlWithCurrentProgramme}?${Object.keys(params).map(key => encodeURIComponent(key) + '=' + params[key]).join('&')}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    }
  })
}

export function postRequest({ url, body, asJson=true }) {
  const urlWithCurrentProgramme = window.programmeHelper.linkHelper(url);
  return fetch(urlWithCurrentProgramme, {
    method: 'POST',
    headers: headers(asJson),
    body: asJson ? JSON.stringify(body) : body
  })
}

export function patchRequest({ url, body, asJson=true }) {
  const urlWithCurrentProgramme = window.programmeHelper.linkHelper(url);
  return fetch(urlWithCurrentProgramme, {
    method: 'PATCH',
    headers: headers(asJson),
    body: asJson ? JSON.stringify(body) : body
  })
}


export function deleteRequest({ url }) {
  const urlWithCurrentProgramme = window.programmeHelper.linkHelper(url);
  return fetch(urlWithCurrentProgramme, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    }
  })
}

function headers(asJson) {
  if(asJson) {
    return {
      'Content-Type': 'application/json',
      'x-csrf-token': getCsrfToken()
    }
  } else {
    return {
      'x-csrf-token': getCsrfToken()
    }
  }
}