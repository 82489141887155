import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import ActionProgressHistory from './ActionProgressHistory';

const ActionProgressModal = ({ action, showing, handleClose }) => {
  return (
    <Modal show={showing} onHide={handleClose}>
      { action &&
        <>
          <Modal.Header>
            <Modal.Title>Progress History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ActionProgressHistory action={action} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
}

export default ActionProgressModal;