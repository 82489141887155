import React from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';

const ModalContentCompletedWorkloadCompanies = ({ companies, company_record_ids }) => {
  return (
    <>
      <div className='padding-top-10 padding-left-10'>
        <p className='text-muted'>Total Companies: {companies.length} / Total Occurrences: {company_record_ids.length}</p>
      </div>
      <CompaniesTable companies={companies} showCount={true} ids={company_record_ids} />
    </>
  );
}

const CompaniesTable = ({ companies, showCount, ids }) => {
  return (
    <table className="modal-body table table-striped companies-modal-loaded completed-workload-modal">
      <tbody>
        { companies.map(company =>
          <tr key={`company_modal_${company.id}`}>
            <td className="col-xs-10">
              { company.name }
              { showCount &&
                <>
                  <br />
                  <small>Count: { ids.filter(id => company.id === id).length }</small>
                </>
              }
            </td>
            <td className="col-xs-2">
              <Button title={`Go to ${company.name}'s company record`} href={window.programmeHelper.linkHelper(`/staff/company_records/${company.id}`)} target="_blank" rel="noopener" bsSize="small">
                <i className="fas fa-search margin-right-5"></i>View Record
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default ModalContentCompletedWorkloadCompanies;