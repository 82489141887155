const defaultState = [];

export const TopicsReducer = (state = defaultState, action) => {
  switch(action.type) {
    case 'UPDATE_TOPIC': {
      let newState = { ...state };
      const topicsToUpdate = newState[action.topic.assessment_topic.assessment_category.id];
      action.topic.updated = true;
      topicsToUpdate[topicsToUpdate.findIndex((topic) => topic.id == action.topic.id)] = action.topic;

      return newState;
    }
  }

  return state;
};