import React, { useState } from "react"
import clsx from "clsx"

import MenuItem from "./MenuItem"

const Menu = ({ id, data, selected, nested = 0 }) => {
  const hidden = id === "topMenu" ? false : selected[nested - 1] != id

  const getItemByType = (item) => {
    if (item.type === "link") {
      return (
        <MenuItem
          id={item.id}
          key={item.id}
          title={item.title}
          href={item.href}
          icon={item.icon}
          iconStyle={item.icon_style}
          selected={selected}
          nested={nested}
        />
      )
    } else if (item.type === "menu") {
      return (
        <MenuItem
          id={item.id}
          key={item.id}
          title={item.title}
          icon={item.icon}
          iconStyle={item.icon_style}
          arrow={true}
          selected={selected}
          nested={nested}
        >
          <Menu
            id={item.id}
            data={item.contents}
            selected={selected}
            nested={nested + 1}
          />
        </MenuItem>
      )
    }
  }

  return (
    <ul className={clsx(hidden && "hidden")}>
      {data.map((item) => getItemByType(item))}
    </ul>
  )
}

export default Menu
