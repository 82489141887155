import React, { useState } from 'react';

import Icon from 'components/Icon';

const ActionPlanFeedback = ({ feedback }) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  
  return (
    <div className={`feedback ${feedbackOpen && 'open'}`}>
      <div className="header" onClick={() => setFeedbackOpen(!feedbackOpen)}>
        <h1>Feedback from last Action Plan submitted</h1>
        <Icon icon={`chevron-${feedbackOpen ? 'up' : 'down'}`} />
      </div>
      <div className="content" dangerouslySetInnerHTML={{ __html: feedback }} />
    </div>
  )
}

export default ActionPlanFeedback;