import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';

import Icon from 'components/Icon';

import { updateAction, removeAction, addErrorsToAction } from 'actions/action_plan/progress';
import { validate, checkPresence, checkLengthLessThan } from 'services/form/validations';

const removeActionValidations = {
  reason_for_removal: [checkPresence, (value) => checkLengthLessThan(value, 200)],
};

const RemoveActionModal = ({ showing, handleClose, actionIndex }) => {
  const dispatch = useDispatch();
  const action = useSelector((state) => state.form.actions[actionIndex]);
  const errors = action.errors || {};

  const handleActionUpdate = (field, value) => {
    dispatch(updateAction(actionIndex, field, value));
  }

  const handleSubmit = () => {
    const submissionErrors = validate(action, removeActionValidations);

    if(Object.keys(submissionErrors).length > 0) {
      dispatch(addErrorsToAction(submissionErrors, actionIndex));
    } else {
      dispatch(removeAction(actionIndex));
      handleClose();
    }
  }

  return (
    <Modal show={showing} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Remove action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup controlId="reason_for_removal" validationState={errors.reason_for_removal ? 'error' : null}>
          <ControlLabel>Reason for removal</ControlLabel>
          <FormControl
            componentClass="textarea"
            type="textarea"
            name="reason_for_removal"
            value={action.reason_for_removal || ''}
            rows="4"
            maxLength="200"
            onChange={(e) => handleActionUpdate(e.target.name, e.target.value)} />
          <HelpBlock>Maximum 200 characters{ errors.reason_for_removal && ` - ${errors.reason_for_removal}` }</HelpBlock>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} className="pull-left">Close</Button>
        <Button onClick={handleSubmit} bsStyle="primary">
          <Icon icon="plus" /> Remove
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveActionModal;