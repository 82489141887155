export const openModal = (company_record_ids, heading="", section="", row="") => ({
  type: 'OPEN_MODAL',
  company_record_ids,
  heading,
  section,
  row
});

export const closeModal = () => ({
  type: 'CLOSE_MODAL'
});

export const loadCompaniesSuccess = (companies_data) => ({
  type: 'LOAD_COMPANIES_SUCCESS',
  companies_data
});

export const loadCompaniesError = () => ({
  type: 'LOAD_COMPANIES_ERROR'
});

export const completedWorkloadLoad = (completed_companies_id) => ({
  type: 'COMPLETED_WORKLOAD_LOAD',
  completed_companies_id
})