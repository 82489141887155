import { postRequest, patchRequest, deleteRequest } from '../base_requests';

export function createCustomActionRequest(actionPlanId, body) {
  return postRequest({ url: `/customer/api/action_plan/${actionPlanId}/custom_actions`, body, asJson: false });
}

export function updateCustomActionRequest(actionPlanId, customActionId, body) {
  return patchRequest({ url: `/customer/api/action_plan/${actionPlanId}/custom_actions/${customActionId}`, body, asJson: false });
}

export function deleteCustomActionRequest(actionPlanId, customActionId) {
  return deleteRequest({ url: `/customer/api/action_plan/${actionPlanId}/custom_actions/${customActionId}` });
}