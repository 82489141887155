import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { openModal } from 'actions/reports/workload';
import { current_workload_stage_names } from 'services/current_workload_helper';

const CurrentWorkloadTable = ({ data, handleOpenModal }) =>
  <div className="current-workload-table workload-report-table">
    <h1 className="padding-top-10 padding-left-10">Current Workload (Live)</h1>
    <small className="padding-left-10 text-muted">Please note this table displays results without hibernated and removed companies.</small>
    <table className="table">
      <CurrentWorkloadTableHeaderRow data={data} />
      <tbody>
        <CurrentWorkloadTableRow
          key={`current_workload_table_total_companies_advised`}
          data={data}
          stage={'total_companies_advised'}
          handleOpenModal={handleOpenModal} />

        { window.programmeHelper.isDefaultProgramme() && <CurrentWorkloadTableRow
          key={`current_workload_table_pending_capability_questionnaires`}
          data={data}
          stage={'pending_capability_questionnaires'}
          handleOpenModal={handleOpenModal} /> }
        { window.programmeHelper.assessmentType() === 'development' && 
          <CurrentWorkloadTableRow
            key={`current_workload_table_in_assessment`}
            data={data}
            stage={'in_assessment'}
            handleOpenModal={handleOpenModal} /> }
        <CurrentWorkloadTableRow
          key={`current_workload_table_pending_self_diagnostics`}
          data={data}
          stage={'pending_self_diagnostics'}
          handleOpenModal={handleOpenModal} />
          
        <CurrentWorkloadTableRow
          key={`current_workload_table_on_sites_to_book`}
          data={data}
          stage={'on_sites_to_book'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_upcoming_on_site_visits`}
          data={data}
          stage={'upcoming_on_site_visits'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_on_site_drafts_to_submit`}
          data={data}
          stage={'on_site_drafts_to_submit'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_on_site_drafts_rejected`}
          data={data}
          stage={'on_site_drafts_rejected'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_on_site_drafts_pending_acceptance`}
          data={data}
          stage={'on_site_drafts_pending_acceptance'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_initial_action_plan_required`}
          data={data}
          stage={'initial_action_plan_required'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_action_plans_requiring_feedback`}
          data={data}
          stage={'action_plans_requiring_feedback'}
          handleOpenModal={handleOpenModal} />

        { window.programmeHelper.assessmentType() === 'development' && 
          <>
            <CurrentWorkloadTableRow
              key={`current_workload_table_pending_pre_granting`}
              data={data}
              stage={'pre_granting'}
              handleOpenModal={handleOpenModal} />
            <CurrentWorkloadTableRow
              key={`current_workload_table_granted`}
              data={data}
              stage={'granted'}
              handleOpenModal={handleOpenModal} />
          </> }
        
        { window.programmeHelper.assessmentType() === 'core' && 
          <>
            <CurrentWorkloadTableRow
              key={`current_workload_table_pending_f4n_pre_granting`}
              data={data}
              stage={'f4n_pre_granting'}
              handleOpenModal={handleOpenModal} />
            <CurrentWorkloadTableRow
              key={`current_workload_table_in_assessment`}
              data={data}
              stage={'in_assessment'}
              handleOpenModal={handleOpenModal} />
            <CurrentWorkloadTableRow
              key={`current_workload_table_f4n_status_granted`}
              data={data}
              stage={'f4n_status_granted'}
              handleOpenModal={handleOpenModal} />
          </> }
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_total_companies_at_cq`}
          data={data}
          stage={'total_companies_at_cq'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_total_companies_in_assessment`}
          data={data}
          stage={'total_companies_in_assessment'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_total_granted_companies`}
          data={data}
          stage={'total_granted_companies'}
          handleOpenModal={handleOpenModal} />
        
        <CurrentWorkloadTableRow
          key={`current_workload_table_total_hibernating_companies`}
          data={data}
          stage={'total_hibernating_companies'}
          handleOpenModal={handleOpenModal} />

        { (window.programmeHelper.identifier === 'f4n' || window.programmeHelper.identifier === 'original_f4n') &&
          <>
            <CurrentWorkloadTableRow
              key={`current_workload_table_require_maintain_and_sustain`}
              data={data}
              stage={'require_maintain_and_sustain'}
              handleOpenModal={handleOpenModal} />
            
            <CurrentWorkloadTableRow
              key={`current_workload_table_booked_maintain_and_sustain`}
              data={data}
              stage={'booked_maintain_and_sustain'}
              handleOpenModal={handleOpenModal} />
          </>
        }
        <CurrentWorkloadTableRow
          key={`current_workload_table_upcoming_arranged_visits`}
          data={data}
          stage={'upcoming_arranged_visits'}
          handleOpenModal={handleOpenModal} />
      </tbody>
    </table>
  </div>

const CurrentWorkloadTableHeaderRow = ({ data }) =>
  <thead>
    <tr>
      <th></th>
      { Object.values(data).map((dataRow) =>
        <th key={`current_workload_table_header_${dataRow.name}`}>{ dataRow.name }</th>
      ) }
    </tr>
  </thead>

const CurrentWorkloadTableRow = ({ data, stage, handleOpenModal }) =>
  <tr className={stage}>
    <td>
      { current_workload_stage_names[stage].name }
      { current_workload_stage_names[stage].help_text &&
          <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
              <Popover title={current_workload_stage_names[stage].name} id={`popover-${stage}`}>{ current_workload_stage_names[stage].help_text }</Popover>
            }>
            <i className="fas fa-question-circle help-text-icon" />
          </OverlayTrigger>
        }
    </td>
    { Object.values(data).map((dataRow, index) =>
      <td key={`current_workload_table_row_${stage}_${index}`}>
        <span className="current-workload-count" onClick={() => handleOpenModal(dataRow.data[stage].company_record_ids, `Current workload for ${dataRow.name} - ${current_workload_stage_names[stage].name}`, 'current_workload', stage)}>
          { dataRow.data[stage].count }
        </span>
      </td>
    )}
  </tr>

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleOpenModal: openModal
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(CurrentWorkloadTable)