import React, { useEffect, useRef, useState } from "react"

import clsx from "clsx"

import Menu from "./Menu"

const MenuContainer = ({ menuJson, initialSelected }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [selected, setSelected] = useState(initialSelected)
  const navRef = useRef()
  const menuRef = useRef()

  const handleEscapeKeyPress = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  })

  // Handle onBlur event to close menu when it loses focus due to
  // tabbing out of it.
  // Checks if the new activeElement is in the menu so it doesn't
  // close if the user clicks out of their browser window.
  // Needs to use setTimeout to prevent clicking on items within
  // the menu being counted as losing focus.
  const handleBlur = () => {
    setTimeout(() => {

      if (!navRef.current.contains(document.activeElement)) {
        setShowMenu(false)
      }
    })
  }

  const handleClick = (event) => {
    if (event.target.id === "menu") {
      return
    }

    const listElement = event.target.closest("li")
    const id = listElement.dataset.id
    const nested = parseInt(listElement.dataset.nested)

    setSelected((oldSelected) => {
      const newSelected = [...oldSelected]
      newSelected[nested] = id
      
      // reset lower level selected item when higher level item is clicked
      if (nested === 0) {
        newSelected[1] = null
        newSelected[2] = null
      } else if (nested === 1) {
        newSelected[2] = null
      }
      
      return newSelected
    })
  }

  return (
    <nav onBlur={handleBlur} ref={navRef}>
      <button
        aria-expanded={showMenu}
        className="menu-button"
        onClick={() => setShowMenu(!showMenu)}
        type="button"
      >
        <i className="fas fa-bars" />
        {" Menu"}
      </button>
      <div 
        id="menu"
        className={clsx(!showMenu && "hidden")}
        onClick={handleClick}
        tabIndex={-1}
        ref={menuRef}
      >
        <Menu id="topMenu" data={menuJson} selected={selected} />
      </div>
    </nav>
  )
}

export default MenuContainer
