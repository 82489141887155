import { serialize } from 'object-to-formdata';

import { saveTopicGoalRequest } from 'services/api/action_plan/initial_api';
import { updateTopic, savingForm, savingComplete, unselectTopic, addErrorsToAction, updateScores } from 'actions/action_plan/progress';
import { validate, checkPresence, checkLengthLessThan } from 'services/form/validations';

import { mutateNewDocuments } from './_helpers';

const actionsValidators = {
  comment: [checkPresence, (value) => checkLengthLessThan(value, 400)],
  completion_percentage: [checkPresence],
};

export const saveTopicGoalMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== 'SAVE_TOPIC') {
    return next(action);
  }
  const state = getState();

  dispatch(savingForm());

  let anyErrors = false;

  state.form.actions.forEach((a, index) => {
    if(a.changed && a.removed !== true && a.previous_actions) {
      const errors = validate(a, actionsValidators);
      if(Object.keys(errors).length > 0) {
        anyErrors = true;
        dispatch(addErrorsToAction(errors, index));
      }
    } 
  });

  if(anyErrors) {
    dispatch(savingComplete());
    $.flashAlert('Please check the form for errors', 'alert-danger');
    return next(action);
  }

  saveTopicGoalRequest(state.form.currentTopicGoal.id, serialize(payloadFromFormState(state.form), { indices: true }))
    .then((res) => res.json())
    .then(({ topic, scores, status }) => {
      if (status === 500) {
        throw new Error(topic.error);
      }
      if (status >= 400) {
        throw new Error("Error saving form, please try again or contact support if the error persists");
      } else {
        dispatch(updateTopic(topic));
        dispatch(updateScores(scores, topic.assessment_topic.assessment_category.id));
        dispatch(savingComplete());
        dispatch(unselectTopic());
      $.flashAlert('Progress saved', 'alert-success');
        return next(action);
      }
    })
    .catch(err => {
      $.flashAlert(err.message, 'alert-danger');
      dispatch(savingComplete());
    });
}

function payloadFromFormState(form) {
  return {
    action_plan_topic_goal: {
      assessment_option_id: form.selectedOption.id,
      no_action_reason: form.noActionReason,
      no_action: form.noAction,
      score: form.selectedOption.score,
      topic_goal_actions_attributes: changedActions(form.actions).map((action) => {
        return {
          id: action.id,
          comment: action.removed ? action.reason_for_removal : action.comment,
          completion_percentage: action.completion_percentage,
          description: action.description,
          assigned_to: action.assigned_to,
          deadline: action.deadline,
          removed: action.removed ? true : false,
          action_document_logs_attributes: action.action_document_logs ? mutateNewDocuments(action.action_document_logs) : []
        }
      })
    }
  };
}

function changedActions(actions) {
  return actions.filter((action) => action.changed)
}