import I18n from 'locales/index.js'

export const current_workload_stage_names = {
  pending_capability_questionnaires: {
    name: I18n.t('staff.reports.workload_report.current_workload.pending_capability_questionnaires.name'),
    help_text: false
  },
  pending_self_diagnostics: {
    name: I18n.t('staff.reports.workload_report.current_workload.pending_self_diagnostics.name'),
    help_text: false
  },
  on_sites_to_book: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_sites_to_book.name'),
    help_text: false
  },
  upcoming_on_site_visits: {
    name: I18n.t('staff.reports.workload_report.current_workload.upcoming_on_site_visits.name'),
    help_text: false
  },
  on_site_drafts_to_submit: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_to_submit.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_to_submit.help_text')
  },
  on_site_drafts_rejected: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_rejected.name'),
    help_text: false
  },
  on_site_drafts_pending_acceptance: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_pending_acceptance.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_pending_acceptance.help_text')
  },
  initial_action_plan_required: {
    name: I18n.t('staff.reports.workload_report.current_workload.initial_action_plan_required.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.initial_action_plan_required.help_text')
  },
  action_plans_requiring_feedback: {
    name: I18n.t('staff.reports.workload_report.current_workload.action_plans_requiring_feedback.name'),
    help_text: false
  },
  pre_granting: {
    name: I18n.t('staff.reports.workload_report.current_workload.pre_granting.name'),
    help_text: false
  },
  f4n_pre_granting: {
    name: I18n.t('staff.reports.workload_report.current_workload.f4n_pre_granting.name'),
    help_text: false
  },
  upcoming_arranged_visits: {
    name: I18n.t('staff.reports.workload_report.current_workload.upcoming_arranged_visits.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.upcoming_arranged_visits.help_text')
  },
  total_companies_at_cq: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_companies_at_cq.name'),
    help_text: false
  },
  total_companies_in_assessment: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_companies_in_assessment.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_companies_in_assessment.help_text')
  },
  granted: {
    name: I18n.t('staff.reports.workload_report.current_workload.granted.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.granted.help_text')
  },
  f4n_status_granted: {
    name: I18n.t('staff.reports.workload_report.current_workload.f4n_status_granted.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.f4n_status_granted.help_text')
  },
  in_assessment: {
    name: I18n.t('staff.reports.workload_report.current_workload.in_assessment.name'),
    help_text: '',
  },
  total_granted_companies: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_granted_companies.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_granted_companies.help_text')
  },
  total_companies_advised: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_companies_advised.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_companies_advised.help_text')
  },
  total_hibernating_companies: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_hibernating_companies.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_hibernating_companies.help_text')
  },
  require_maintain_and_sustain: {
    name: I18n.t('staff.reports.workload_report.current_workload.require_maintain_and_sustain.name'),
    help_text: false
  },
  booked_maintain_and_sustain: {
    name: I18n.t('staff.reports.workload_report.current_workload.booked_maintain_and_sustain.name'),
    help_text: false
  }
}
