import React from 'react';

import ModalContentCompanies from './contents/Companies';
import ModalContentCompletedWorkloadCompanies from './contents/CompletedWorkloadCompanies';

const ModalContentSwitcher = ({ companies, section, company_record_ids }) => {
  switch(section) {
    case 'completed_workload': {
      return <ModalContentCompletedWorkloadCompanies companies={companies} company_record_ids={company_record_ids} />
    }
    default: {
      return <ModalContentCompanies companies={companies} />
    }
  }
}

export default ModalContentSwitcher;