import React, { useRef } from 'react';
import { Provider, useSelector } from 'react-redux';

import App from 'components/App';
import ActionPlanIndexScreen from './ActionPlanIndexScreen';
import ActionPlanTopicScreen from './ActionPlanTopicScreen';

import buildStore from 'stores/action_plan/progress';

import 'styles/action_plan/progress.scss';
import 'styles/action_plan/shared.scss';

const ActionPlanProgressPage = ({ assessment_topics, assessment_categories, last_action_plan, action_plan, scores, custom_actions, editing=true, checklist_items, ready_for_checklist_actions }) => {
  const storeRef = useRef(buildStore({ topics: assessment_topics, categories: assessment_categories, editingEnabled: editing, scores, custom_actions, action_plan, checklist_items }));

  return (
    <App>
      <Provider store={storeRef.current}>
        <ActionPlanProgressScreenSwitcher readyForChecklistActions={ready_for_checklist_actions} lastActionPlan={last_action_plan} />
      </Provider>
    </App>
  );
}

const ActionPlanProgressScreenSwitcher = ({ readyForChecklistActions, lastActionPlan }) => {
  const screen = useSelector((state) => state.form.currentTopicGoal ? 'topic' : 'index');

  if(screen === 'topic') {
    return <ActionPlanTopicScreen />;
  } else {
    return <ActionPlanIndexScreen readyForChecklistActions={readyForChecklistActions} lastActionPlan={lastActionPlan} />;
  }
}

export default ActionPlanProgressPage;