import { serialize } from 'object-to-formdata';

import { createCustomActionRequest, updateCustomActionRequest } from 'services/api/action_plan/custom_actions';
import { savingForm, savingComplete, customActionAdded, customActionUpdated, addErrorsToCustomActionForm, savingCompleteWithError } from 'actions/action_plan/progress';

import { mutateNewDocuments } from './_helpers';

export const saveCustomActionMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== 'SAVE_CUSTOM_ACTION') {
    return next(action);
  }

  const state = getState();
  const customActionState = state.customActions;
  const type = customActionState.form.type;

  dispatch(savingForm());

  let request = undefined;

  if(type === 'edit') {
    request = (params) => updateCustomActionRequest(state.actionPlan.id, customActionState.form.id, params);
  } else {
    request = (params) => createCustomActionRequest(state.actionPlan.id, params);
  }

  request(serialize(payloadFromFormState(customActionState.form), { indices: true }))
    .then((res) => {
      if(res.status >= 401) {
        res.json().then(response => console.error(response));
        throw new Error("Error saving custom action, please try again or contact support if the error persists");
      } else {
        return res;
      }
    })
    .then((res) => res.json())
    .then(({ custom_action, errors }) => {
      if(errors) {
        dispatch(addErrorsToCustomActionForm(errors));
        dispatch(savingCompleteWithError());
        $.flashAlert('There was an error saving the custom action. Please check the form for errors.', 'alert-danger');
      } else {
        if(type === 'edit') {
          dispatch(customActionUpdated(custom_action));
        } else {
          dispatch(customActionAdded(custom_action));
        }
        dispatch(savingComplete());
        $.flashAlert('Custom action saved.', 'alert-success');
      }
    })
    .catch(err => {
      $.flashAlert(err.message, 'alert-danger');
      dispatch(savingCompleteWithError());
    });
}

function payloadFromFormState(form) {
  return {
    custom_action: {
      completion_percentage: form.completion_percentage,
      description: form.description,
      assigned_to: form.assigned_to,
      deadline: form.deadline,
      progress: form.progress,
      action_document_logs_attributes: form.action_document_logs ? mutateNewDocuments(form.action_document_logs) : []
    }
  }
}