import React from 'react';

import Icon from 'components/Icon';

const DeadlineIcon = ({ deadline }) => {
  const dateNow = new Date();
  const deadlineDate = new Date(deadline);
  const dateOneMonthFromNow = new Date()
  dateOneMonthFromNow.setMonth(new Date().getMonth() + 1)

  if(deadlineDate < dateNow) {
    return <Icon icon="exclamation-triangle" className="text-danger" title="This action is overdue." />
  } else if(dateOneMonthFromNow > deadlineDate) {
    return <Icon icon="exclamation-triangle" className="iconDueSoon" title="This action is due soon." />
  } else {
    return null;
  }
}

export default DeadlineIcon;