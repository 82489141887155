import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { WorkloadReducer } from 'reducers/reports/workload';
import { getCurrentWorkloadCompanyRecords } from 'middleware/reports/workload';

import SentryErrorBoundary from 'components/SentryErrorBoundary';
import CurrentWorkloadSection from './CurrentWorkloadSection';
import CompaniesModal from './CompaniesModal';

const WorkloadReportPage = ({ ia_current_workload }) => {
  const store = createStore(
    combineReducers({ workload: WorkloadReducer }),
    applyMiddleware(getCurrentWorkloadCompanyRecords)
  );

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <div className="workload-report-wrapper">
          <CurrentWorkloadSection data={ia_current_workload} />
          <CompaniesModal />
        </div>
      </Provider>
    </SentryErrorBoundary>
  )
}


export default WorkloadReportPage;
