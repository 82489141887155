import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';

import { addNewCustomAction, editCustomAction, cancelCustomActionEditing } from 'actions/action_plan/progress';
import { formatDateString } from 'services/date_helpers';

import CustomActionFormModal from './CustomActionFormModal';
import ActionProgressModal from '../shared/ActionProgressModal';
import Icon from 'components/Icon';
import DeadlineIcon from '../DeadlineIcon';

const CustomActionsSection = () => {
  const dispatch = useDispatch();
  const customActions = useSelector((state) => state.customActions.customActions);
  const editable = useSelector((state) => state.form.editingEnabled);
  const modalOpen = useSelector((state) => state.customActions.modalActive);
  const [actionShowingProgressFor, setActionShowingProgressFor] = useState(undefined);

  let bodyContent = undefined;

  const handleEditCustomActionProgressClick = (customAction) => {
    dispatch(editCustomAction(customAction));
  }

  if(customActions.length === 0) {
    bodyContent = (
      <div className="text-center">
        <p className="lead">There are no custom actions. Click the button below to add one.</p>
      </div>
    )
  } else {
    bodyContent = (
      <table className="table margin-bottom-10 customActionsTable">
        <thead>
          <tr>
            <th className="topic">Action Description</th>
            <th className="assignedTo">Assigned to</th>
            <th className="deadline">Deadline</th>
            <th className="currentProgress">Progress</th>
            <th className="document">Documents</th>
            { editable && <th className="actions"></th> }
          </tr>
        </thead>
        <tbody>
          { customActions.map((customAction) =>
            <tr className={`topic custom_action_${customAction.id}`} key={`custom_action_${customAction.id}`}>
              <td>{ customAction.description }</td>
              <td>{ customAction.assigned_to }</td>
              <td>{ formatDateString(customAction.deadline) } { customAction.completion_percentage != 100 && <DeadlineIcon deadline={customAction.deadline} /> }</td>
              <td>
                { customAction.completion_percentage }%
                { customAction.completion_percentage == 100 && <Icon icon="check" className=" margin-left-10 text-success" /> }
              </td>
              <td>
                { customAction.action_document_logs ? customAction.action_document_logs.length : 'None' }
              </td>
              <td className="text-right">
                <ButtonGroup bsSize="small">
                  <Button bsStyle="default" onClick={() => setActionShowingProgressFor(customAction)}>
                    <Icon icon="history" className="margin-right-5" />
                    History
                  </Button>
                  { editable && customAction.completion_percentage != 100 && (
                    <Button bsStyle="default" onClick={() => handleEditCustomActionProgressClick(customAction)}>
                      <Icon icon="edit" className="margin-right-5" />
                      Update Progress
                    </Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ) }
        </tbody>
      </table>
    );
  }

  return (
    <div className="category customActions">
      <div className="header">
        <h1>Custom Actions</h1>
      </div>
      <p className="lead">Custom Actions allow you to track progress on actions outside of the scope of the main Action Plan. This can range from smaller actions like uploading a particular document, to wider process improvements like training.</p>
      { bodyContent }
      { editable &&
        <>
          <div className="text-right">
            <button onClick={() => dispatch(addNewCustomAction())} className="btn btn-default"><Icon icon="plus" /> Add new custom action</button>
          </div>
          <CustomActionFormModal showing={modalOpen} handleClose={() => dispatch(cancelCustomActionEditing())} />
        </>
      }
      <ActionProgressModal action={actionShowingProgressFor} showing={actionShowingProgressFor !== undefined} handleClose={() => setActionShowingProgressFor(undefined)} />
    </div>
  );
}

export default CustomActionsSection;