import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup, ControlLabel, FormControl, Col, Row, HelpBlock } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { addAction, updateAction, removeAction, updateNoActionText } from 'actions/action_plan/initial';

import "react-datepicker/dist/react-datepicker.css";

const ActionPlanTopicActions = () => {
  const noAction = useSelector((state) => state.form.noAction);

  if(noAction) {
    return <ActionPlanTopicMaintainInput />
  } else {
    return <ActionPlanTopicImprovementInputs />
  }
}

const ActionPlanTopicImprovementInputs = () => {
  const dispatch = useDispatch();
  const actions = useSelector((state) => state.form.actions.filter((action) => action._destroy === undefined));
  const saving = useSelector((state) => state.form.saving);

  const handleActionUpdate = (index, field, value) => {
    dispatch(updateAction(index, field, value));
  }

  return (
    <div className="improvement">
      <h3>Actions</h3>
      <hr />

      { actions.map((action, index) =>
        <div className="action" key={`action_${action.id || action.temp_id}`}>
          <Row>
            <Col md={6}>
              <FormGroup controlId="description">
                <ControlLabel>Action to be undertaken</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  type="textarea"
                  name="description"
                  value={action.description}
                  rows="4"
                  maxLength="200"
                  onChange={(e) => handleActionUpdate(index, e.target.name, e.target.value)}
                  disabled={saving} />
                <HelpBlock>Maximum 200 characters</HelpBlock>
              </FormGroup>
            </Col>
            <Col md={4} lg={5}>
              <FormGroup controlId={`assigned_to_${action.id || action.temp_id}`}>
                <ControlLabel>Action owner</ControlLabel>
                <FormControl
                  type="text"
                  name={`assigned_to`}
                  value={action.assigned_to}
                  onChange={(e) => handleActionUpdate(index, e.target.name, e.target.value)}
                  disabled={saving} />
              </FormGroup>
              <FormGroup controlId="deadline">
                <ControlLabel>Completion target date</ControlLabel>
                <DatePicker
                  selected={Date.parse(action.deadline)}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => handleActionUpdate(index, "deadline", date)}
                  className='form-control'
                  disabled={saving} />
              </FormGroup>
            </Col>
            <Col md={2} lg={1}>
              <button className="btn btn-default margin-top-25" onClick={() => dispatch(removeAction(index))} disabled={Object.keys(actions).length === 1 || saving}>
                Remove
              </button>
            </Col>
          </Row>
        </div>
      )}
      <button className="btn btn-default" onClick={(e) => { dispatch(addAction()) } }>
        <i className="fas fa-plus margin-right-5"></i>
        Add action
      </button>
    </div>
  );
}

const ActionPlanTopicMaintainInput = () => {
  const dispatch = useDispatch();
  const noActionReason = useSelector((state) => state.form.noActionReason);
  return (
    <Row className="maintain padding-top-10">
      <Col md={6}>
        <FormGroup controlId="maintainInput">
          <ControlLabel>Reason for maintaining current standard</ControlLabel>
          <FormControl
            componentClass="textarea"
            type="textarea"
            placeholder=""
            value={noActionReason || ''}
            onChange={(e) => dispatch(updateNoActionText(e.target.value))} />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default ActionPlanTopicActions;