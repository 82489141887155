import { serialize } from 'object-to-formdata';

import { createChecklistActionRequest, updateChecklistActionRequest } from 'services/api/action_plan/checklist_actions';
import { savingForm, savingComplete, checklistActionAdded, checklistActionUpdated, addErrorsToChecklistActionForm, savingCompleteWithError } from 'actions/action_plan/progress';

import { mutateNewDocuments } from './_helpers';

export const saveChecklistActionMiddleware = ({ dispatch, getState }) => next => action => {
  if(action.type !== 'SAVE_CHECKLIST_ACTION') {
    return next(action);
  }

  const state = getState();
  const checklistItemsState = state.checklistItems;
  const type = checklistItemsState.form.type;

  dispatch(savingForm());

  let request = undefined;

  if(type === 'edit') {
    request = (params) => updateChecklistActionRequest(state.actionPlan.id, checklistItemsState.form.id, params);
  } else {
    request = (params) => createChecklistActionRequest(state.actionPlan.id, params);
  }

  request(serialize(payloadFromFormState(checklistItemsState.form), { indices: true }))
    .then((res) => {
      if(res.status >= 401) {
        res.json().then(data => console.error(data));
        throw new Error("Error saving checklist action, please try again or contact support if the error persists");
      } else {
        return res;
      }
    })
    .then((res) => res.json())
    .then(({ checklist_action, errors }) => {
      if(errors) {
        dispatch(addErrorsToChecklistActionForm(errors));
        dispatch(savingCompleteWithError());
        $.flashAlert('There was an error saving the checklist action. Please check the form for errors.', 'alert-danger');
      } else {
        if(type === 'edit') {
          dispatch(checklistActionUpdated(checklistItemsState.form.category_id, checklist_action));
        } else {
          dispatch(checklistActionAdded(checklistItemsState.form.category_id, checklist_action));
        }
        dispatch(savingComplete());
        $.flashAlert('Checklist action saved.', 'alert-success');  
      }
    })
    .catch(err => {
      $.flashAlert(err.message, 'alert-danger');
      dispatch(savingCompleteWithError());
    });
}

function payloadFromFormState(form) {
  return {
    checklist_action: {
      completion_percentage: form.completion_percentage,
      description: form.description,
      assigned_to: form.assigned_to,
      deadline: form.deadline,
      checklist_item_id: form.checklist_item_id,
      progress: form.progress,
      action_document_logs_attributes: form.action_document_logs ? mutateNewDocuments(form.action_document_logs) : []
    }
  }
}