import { updateScrollPosition } from 'actions/action_plan/progress';

export const handleScrollPosition = ({ dispatch }) => next => action => {
  if(action.type !== 'CHANGE_TOPIC') {
    return next(action);
  }

  dispatch(updateScrollPosition(window.scrollY));

  return next(action);
}