import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Col, Row, FormGroup, FormControl, ControlLabel, HelpBlock, InputGroup } from 'react-bootstrap';

import Icon from 'components/Icon';
import ActionDetails from './ActionDetails'
import RemoveActionModal from './RemoveActionModal';
import ActionFormModal from './actions/ActionFormModal';
import ActionProgressModal from './shared/ActionProgressModal';

import { updateAction, actionFormCreate } from 'actions/action_plan/progress';
import SupportingEvidence from './SupportingEvidence';
import DeadlineIcon from './DeadlineIcon';

const ActionPlanActionsForm = ({ editable }) => {
  const dispatch = useDispatch();
  const [showingAddActionModal, setShowingAddActionModal] = useState(false);
  const topic = useSelector((state) => state.form.currentTopicGoal);
  const actions = useSelector((state) => state.form.actions.filter((action) => action.removed === undefined || action.remove === false));

  const handleNewActionModalOpen = () => {
    setShowingAddActionModal(true);
    dispatch(actionFormCreate());
  };

  return (
    <div className="actionPlanActionsForm" key={topic.id}>
      { editable && <Button onClick={handleNewActionModalOpen} bsStyle="primary" className="pull-right"><Icon icon="plus" /> New action</Button> }
      { editable && <ActionFormModal showing={showingAddActionModal} handleClose={() => setShowingAddActionModal(false)} topic={topic} /> }
      <h2>Actions</h2>
      <div className="clearfix"></div>
      <div className="actions">
        { actions.map((action, index) =>
          editable ?
            <ActionForm action={action} index={index} key={index} />
          :
            <ActionInfo action={action} index={index} key={index} />
        )}
        { actions.length === 0 && <p className="text-center lead margin-top-20">There are no actions</p>}
      </div>
    </div>
  )
}

const ActionForm = ({ action, index }) => {
  const dispatch = useDispatch();
  const [showingProgressModal, setShowingProgressModal] = useState(false);
  const [showingEditModal, setShowingEditModal] = useState(false);
  const [showingRemoveModal, setShowingRemoveModal] = useState(false);
  const errors = action.errors || {};

  const handleActionUpdate = (field, value) => {
    dispatch(updateAction(index, field, value));
  }

  const handleActionFormCreate = () => {
    dispatch(actionFormCreate(index));
    setShowingEditModal(true);
  };

  const handleFileAdditions = (files) => {
    files = Array.from(files)
    const newDocuments = action.action_document_logs || [];
    files.forEach((file, index) => {
      newDocuments.push({ new: true, id: Date.now() + index, action_document: { file }});
    });

    dispatch(updateAction(index, 'action_document_logs', newDocuments));
  }

  const handleFileRemove = (fileIndex) => {
    const newDocuments = action.action_document_logs;
    if(newDocuments[fileIndex].new) {
      newDocuments.splice(fileIndex, 1);
    } else {
      newDocuments[fileIndex]._destroy = true;
    }

    dispatch(updateAction(index, 'action_document_logs', newDocuments));
  }

  return (
    <div className="action" key={`action_${action.id || action.temp_id}`}>
      <div className="inputs">
        <FormGroup
          controlId="comment"
          validationState={errors.comment ? 'error' : null}>
          <ControlLabel>Progress description</ControlLabel>
          <FormControl
            name="comment"
            componentClass="textarea"
            value={action.comment || ''}
            onChange={(e) => handleActionUpdate(e.target.name, e.target.value)}
            rows="6"
            maxLength="400" />
          <HelpBlock>Maximum 400 characters{ errors.comment && ` - ${errors.comment}` }</HelpBlock>
        </FormGroup>
        <Row>
          <Col md={12}>
            <FormGroup
              controlId="completion_percentage"
              validationState={errors.completion_percentage ? 'error' : null}>
              <ControlLabel>Current progress percentage</ControlLabel>
              <InputGroup>
                <FormControl
                  name="completion_percentage"
                  type="number"
                  onChange={(e) => handleActionUpdate(e.target.name, e.target.value)}
                  value={action.completion_percentage} />
                <InputGroup.Addon>%</InputGroup.Addon>
              </InputGroup>
              { errors.completion_percentage && <HelpBlock>{errors.completion_percentage}</HelpBlock> }
            </FormGroup>
          </Col>
          <Col md={12}>
            <SupportingEvidence documents={action.action_document_logs} handleFileAdditions={handleFileAdditions} handleFileRemove={handleFileRemove} />
          </Col>
        </Row>
      </div>
      <div className="details">
        <ActionDetails action={action} />
        <div className="actionActions">
          <Button bsStyle="danger" onClick={() => setShowingRemoveModal(true)}><Icon icon="trash" /> Remove</Button>
          <Button onClick={() => handleActionFormCreate(index)} className="margin-left-10"><Icon icon="edit" /> Edit action</Button>
          { action.previous_actions && <Button onClick={() => setShowingProgressModal(true)} className="margin-left-10"><Icon icon="history" /> View Action Progress History</Button> }
        </div>
      </div>
      { action.previous_actions && <ActionProgressModal action={action} showing={showingProgressModal} handleClose={() => setShowingProgressModal(false)} /> }
      <ActionFormModal actionIndex={index} showing={showingEditModal} handleClose={() => setShowingEditModal(false)} />
      <RemoveActionModal actionIndex={index} showing={showingRemoveModal} handleClose={() => setShowingRemoveModal(false)} />
    </div>
  )
}


const ActionInfo = ({ action }) => {
  const [showingProgressModal, setShowingProgressModal] = useState(false);

  return (
    <div className="action">
      <div className="inputs">
        { action.comment &&
          <>
            <p><b>Latest progress</b></p>
            <p className="lead">{ action.comment }</p>
          </>
        }
        <p><b>Completion percentage</b></p>
        <p className="lead">{ action.completion_percentage }%</p>
        <p><b>Supporting Documentation</b></p>
        { action.action_document_logs.length > 0 ?
          <ul className="list-unstyled">
            {
              action.action_document_logs.map(({ action_document }) =>
                <li key={`action_document_${action_document.id}`}><a href={window.programmeHelper.linkHelper(`/customer/action_documents/${action_document.id}`)}><Icon icon="download" /> { action_document.file.url.split('/').pop() }</a></li>
              )
            }
          </ul>
          :
          <p>No evidence uploaded</p>
        }
      </div>
      <div className="details">
        <ActionDetails action={action} />
        <div className="actionActions">
          { action.previous_actions && <Button onClick={() => setShowingProgressModal(true)} className="margin-left-10"><Icon icon="history" /> View Action Progress History</Button> }
        </div>
      </div>
      { action.previous_actions && <ActionProgressModal action={action} showing={showingProgressModal} handleClose={() => setShowingProgressModal(false)} /> }
    </div>
  );
}

export default ActionPlanActionsForm;