import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import I18n from 'locales/index.js'

import { addNewChecklistAction, editChecklistAction } from 'actions/action_plan/progress';
import { formatDateString } from 'services/date_helpers';

import ChecklistActionFormModal from './ChecklistActionFormModal';
import ActionProgressModal from '../shared/ActionProgressModal';
import Icon from 'components/Icon';
import DeadlineIcon from '../DeadlineIcon';

import './ChecklistActionsSection.scss';

const ChecklistActionsSection = () => {
  const dispatch = useDispatch();
  const checklistCategories = useSelector((state) => state.checklistItems.items);
  const editable = useSelector((state) => state.form.editingEnabled);
  const modalOpen = useSelector((state) => state.checklistItems.modalActive);
  const [actionShowingProgressFor, setActionShowingProgressFor] = useState(undefined);

  return (
    <>
      <p className="lead">{ I18n.t('customer.action_plan.checklist_actions.long_description') }</p>
      { Object.values(checklistCategories).map((checklistCategory) => (
        <div className="category checklistActions" key={`checklist_category_${checklistCategory.id}`}>
          <div className="checklistCategory">
            <h2>{ checklistCategory.name }</h2>
            { Object.values(checklistCategory.items).map((item) => (
              <div className={`checklistItemDetails ${checklistColours[item.checklist_choice.score]}`} key={`checklist_item_${item.id}`}>
                <div className="checklistItemHeader">
                  <div className="item">
                    { item.description }
                  </div>
                  <div className="standard">
                    { item.checklist_choice.description }
                  </div>
                  { editable &&
                    <Button
                      className="addActionButton"
                      bsStyle="default"
                      bsSize="xsmall"
                      onClick={() => dispatch(addNewChecklistAction(item.checklist_category_id, item.id))}>
                      <Icon icon="plus" /> Add action
                    </Button>
                  }
                </div>
                <CheckListActions actions={item.actions} editable={editable} categoryId={item.checklist_category_id} handleHistoryClick={setActionShowingProgressFor} />
              </div>
            ))}
          </div>
        </div>
      ))}
      { editable &&
        <ChecklistActionFormModal showing={modalOpen} />
      }
      <ActionProgressModal action={actionShowingProgressFor} showing={actionShowingProgressFor !== undefined} handleClose={() => setActionShowingProgressFor(undefined)} />
    </>
  );
}

const checklistColours = [
  '',
  'checklist-color-danger',
  'checklist-color-warning',
  'checklist-color-success',
  'checklist-color-info',
]

const CheckListActions = ({ actions, editable, categoryId, handleHistoryClick }) => {
  const dispatch = useDispatch();

  const handleEditChecklistActionProgressClick = (checklistAction) => {
    dispatch(editChecklistAction(categoryId, checklistAction));
  }

  const handleViewHistoryClick = (checklistAction) => {
    handleHistoryClick(checklistAction);
  }

  if(actions.length > 0) {
    return (
      <table className="table margin-bottom-10 checklistActionsTable">
        <thead>
          <tr>
            <th className="topic">Action Description</th>
            <th className="assignedTo">Assigned to</th>
            <th className="deadline">Deadline</th>
            <th className="currentProgress">Progress</th>
            <th className="document">Documents</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
          { actions.map((checklistAction) =>
            <tr className={`topic checklist_action_${checklistAction.id}`} key={`checklist_action_${checklistAction.id}`}>
              <td>{ checklistAction.description }</td>
              <td>{ checklistAction.assigned_to }</td>
              <td>{ formatDateString(checklistAction.deadline) } { checklistAction.completion_percentage != 100 && <DeadlineIcon deadline={checklistAction.deadline} /> }</td>
              <td>
                { checklistAction.completion_percentage }%
                { checklistAction.completion_percentage == 100 && <Icon icon="check" className=" margin-left-10 text-success" /> }
              </td>
              <td>
                { checklistAction.action_document_logs ? checklistAction.action_document_logs.length : 'None' }
              </td>
              <td className="text-right">
                <Button bsStyle="default" bsSize="xsmall" onClick={() => handleViewHistoryClick(checklistAction)}>
                  <Icon icon="history" className="margin-right-5" />
                  History
                </Button>
                { editable && checklistAction.completion_percentage != 100 && (
                  <Button bsStyle='default' bsSize="xsmall" onClick={() => handleEditChecklistActionProgressClick(checklistAction)}>
                    <Icon icon="edit" className="margin-right-5" />
                    Update Progress
                  </Button>
                )}
              </td>
            </tr>
          ) }
        </tbody>
      </table>
    );
    
  }

  return null;
}

export default ChecklistActionsSection;