import I18n from 'locales/index.js'

import { current_workload_stage_names } from './current_workload_helper';

let default_programme_completed_workload_stage_names;
if (window.programmeHelper.isDefaultProgramme()) {
  default_programme_completed_workload_stage_names = {
    capability_questionnaires_reviewed: {
      name: I18n.t('staff.reports.workload_report.completed_workload.capability_questionnaires_reviewed.name'),
      help_text: I18n.t('staff.reports.workload_report.completed_workload.capability_questionnaires_reviewed.help_text'),
      color: '#e6194B',
    }
  }
}

export const development_assessment_completed_workload_stage_names = {
  self_diagnostics_reviewed: {
    name: I18n.t('staff.reports.workload_report.completed_workload.self_diagnostics_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.self_diagnostics_reviewed.help_text'),
    color: '#f58231',
  },
  on_sites_booked: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_sites_booked.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_sites_booked.help_text'),
    color: '#ffe119',
  },
  on_sites_attended: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_sites_attended.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_sites_attended.help_text'),
    color: '#bfef45',
  },
  on_site_drafts_first_submitted: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_first_submitted.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_first_submitted.help_text'),
    color: '#3cb44b',
  },
  on_site_drafts_accepted: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_accepted.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_accepted.help_text'),
    color: '#42d4f4',
  },
  on_site_drafts_resubmitted: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_resubmitted.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_resubmitted.help_text'),
    color: '#4363d8',
  },
  action_plan_feedback_given: {
    name: I18n.t('staff.reports.workload_report.completed_workload.action_plan_feedback_given.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.action_plan_feedback_given.help_text'),
    color: '#911eb4',
  },
  submitted_for_f4n_granting: {
    name: I18n.t('staff.reports.workload_report.completed_workload.submitted_for_f4n_granting.name'),
    help_text: false,
    color: '#f032e6',
  },
  granted_decisions: {
    name: I18n.t('staff.reports.workload_report.completed_workload.granted_decisions.name'),
    help_text: false,
    color: '#637854',
  },
  pre_granting_visits: {
    name: I18n.t('staff.reports.workload_report.completed_workload.pre_granting_visits.name'),
    help_text: false,
    color: '#d46337',
  },
  maintain_and_sustain_visits: {
    name: I18n.t('staff.reports.workload_report.completed_workload.maintain_and_sustain_visits.name'),
    help_text: false,
    color: '#aaffc3',
  },
  overall_visits: {
    name: I18n.t('staff.reports.workload_report.completed_workload.overall_visits.name'),
    help_text: false,
    color: '#fabebe',
  },
  hibernating_companies: {
    name: I18n.t('staff.reports.workload_report.completed_workload.hibernating_companies.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.hibernating_companies.help_text'),
    color: '#4b4c6b',
  },
}

let completed_workload_stage_names_value;
if (window.programmeHelper.isDefaultProgramme()) {
  completed_workload_stage_names_value = {...default_programme_completed_workload_stage_names, ...development_assessment_completed_workload_stage_names} 
} else {
  completed_workload_stage_names_value = development_assessment_completed_workload_stage_names
}

export const completed_workload_stage_names = completed_workload_stage_names_value
