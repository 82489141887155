import 'react-hot-loader'
import React from 'react';
import { hot } from 'react-hot-loader/root';

import SentryErrorBoundary from 'components/SentryErrorBoundary';

const App = ({ children }) =>
  <SentryErrorBoundary>
    { children }
  </SentryErrorBoundary>

export default hot(App);