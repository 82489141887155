import React from 'react';
import { useSelector } from 'react-redux';

const CategoryScores = ({ categoryId }) => {
  const scores = useSelector((state) => state.scores[categoryId]);
  const editable = useSelector((state) => state.form.editingEnabled);

  return (
    <>
      <div className="scoreTitle">Target Score</div>
      <div className="scoreValue"><span className={colourClassForPercentage(scores.goals_percentage)}>{ scores.goals_percentage }%</span></div>
      <div className="scoreTitle">{ editable ? 'Current' : 'Plan' } score</div>
      <div className="scoreValue"><span className={colourClassForPercentage(scores.current_level_percentage)}>{ scores.current_level_percentage }%</span></div>
    </>
  );
}

function colourClassForPercentage(percentage) {
  if(percentage >= 85) {
    return 'score-dark-green'
  }
  else if(percentage >= 72 ) {
    return 'score-green'
  }
  else if(percentage >= 60 ) {
    return 'score-amber'
  }
  else {
    return 'score-red'
  }
}

export default CategoryScores;