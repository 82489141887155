export const addNewCustomAction = () => ({
  type: 'ADD_NEW_CUSTOM_ACTION'
});

export const editCustomAction = (customAction) => ({
  type: 'EDIT_CUSTOM_ACTION',
  customAction
});

export const saveCustomAction = () => ({
  type: 'SAVE_CUSTOM_ACTION'
});

export const removeCustomAction = (customActionId) => ({
  type: 'REMOVE_CUSTOM_ACTION',
  customActionId
})

export const cancelCustomActionEditing = () => ({
  type: 'CANCEL_CUSTOM_ACTION_EDITING'
});

export const updateCustomActionField = (name, value) => ({
  type: 'UPDATE_CUSTOM_ACTION_FIELD',
  name,
  value
});

export const addErrorsToCustomActionForm = (errors) => ({
  type: 'ADD_ERRORS_TO_CUSTOM_ACTION_FORM',
  errors
});

export const customActionAdded = (customAction) => ({
  type: 'CUSTOM_ACTION_ADDED',
  customAction
});

export const customActionUpdated = (customAction) => ({
  type: 'CUSTOM_ACTION_UPDATED',
  customAction
});

export const customActionRemoved = (customActionId) => ({
  type: 'CUSTOM_ACTION_REMOVED',
  customActionId
});