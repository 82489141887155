import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { WorkloadReducer } from 'reducers/reports/workload';
import { getCompletedWorkloadCompanyRecords } from 'middleware/reports/workload';

import SentryErrorBoundary from 'components/SentryErrorBoundary';
import CompletedWorkloadSection from './CompletedWorkloadSection';
import CompaniesModal from './CompaniesModal';

const WorkloadReportPage = ({ aggregated_completed_workload, aggregated_workload }) => {
  const store = createStore(
    combineReducers({ workload: WorkloadReducer }),
    applyMiddleware(getCompletedWorkloadCompanyRecords)
  );

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <div className="workload-report-wrapper">
          <CompletedWorkloadSection aggregated_completed_workload={aggregated_completed_workload} aggregated_workload={aggregated_workload} />
          <CompaniesModal />
        </div>
      </Provider>
    </SentryErrorBoundary>
  )
}


export default WorkloadReportPage;
