import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 400;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1, pointerEvents: 'initial' },
  entered:  { opacity: 1, pointerEvents: 'initial' },
  exiting:  { opacity: 0, pointerEvents: 'initial' },
  exited:  { opacity: 0, pointerEvents: 'none' },
};

const SavingSpinner = ({ saving }) => (
  <div className="savingSpinner">
    <Transition in={saving} timeout={duration}>
      {state => (
        <div style={{
          ...defaultStyle,
          ...transitionStyles[state] }} className="savingSpinnerInner">
          { state === 'exiting' ? (<p>Saved</p>) : <React.Fragment><i className="fad fa-spinner-third fa-spin margin-right-5"></i> Saving progress</React.Fragment> }
        </div>
      )}
    </Transition>
  </div>
);

export default SavingSpinner;