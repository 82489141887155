import React from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

import ActionEditForm from '../ActionEditForm';
import Icon from 'components/Icon';

import { addAction, saveAction, addErrorsToActionForm } from 'actions/action_plan/progress';
import { validate, checkPresence, checkLengthLessThan } from 'services/form/validations';

const actionValidators = {
  description: [checkPresence, (value) => checkLengthLessThan(value, 400)],
  assigned_to: [checkPresence],
  deadline: [checkPresence],
}

const ActionFormModal = ({ showing, handleClose, actionIndex }) => {
  const dispatch = useDispatch();
  const actionForm = useSelector((state) => state.form.actionForm);
  const type = actionIndex === undefined ? 'new' : 'edit';

  const handleSave = () => {
    const submissionErrors = validate(actionForm, actionValidators);

    if(Object.keys(submissionErrors).length > 0) {
      dispatch(addErrorsToActionForm(submissionErrors));
    } else {
      if(type === 'edit') {
        dispatch(saveAction(actionIndex));
        handleClose();
      } else {
        dispatch(addAction());
        handleClose();
      }
    }
  }

  return (
    <Modal show={showing} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{ type === 'new' ? 'Add new' : 'Edit' } Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActionEditForm />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} className="pull-left">Close</Button>
        <Button onClick={handleSave} bsStyle="primary">
          {
            type === 'edit' ?
              <><Icon icon="save" /> Update action</>
            :
              <><Icon icon="plus" /> Add</>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActionFormModal;