class ProgrammeHelper {
  constructor(programme_identifier, default_programme_identifier, assessment_type) {
    this.identifier = programme_identifier;
    this.default_identifier = default_programme_identifier;
    this.assessment_type = assessment_type;
  }
  
  linkHelper(path) {
    const url = new URL(path, `${window.location.protocol}//${window.location.host}`);
    url.searchParams.set("current_programme", this.identifier);
    return url.href;
  }

  isDefaultProgramme(){
    return this.identifier === this.default_identifier;
  }

  assessmentType() {
    return this.assessment_type;
  }
}

export default ProgrammeHelper;