import React, { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Button, Modal, Row, Col, FormGroup, FormControl, ControlLabel, HelpBlock, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Icon from 'components/Icon';
import SupportingEvidence from '../SupportingEvidence';

import { saveCustomAction, removeCustomAction, addErrorsToCustomActionForm, updateCustomActionField } from 'actions/action_plan/progress';
import { validate, checkPresence, checkLengthLessThan, checkBetweenLengths } from 'services/form/validations';

const customActionValidators = {
  description: [checkPresence, (value) => checkLengthLessThan(value, 400)],
  assigned_to: [checkPresence],
  deadline: [checkPresence],
  completion_percentage: [checkPresence, (value) => checkBetweenLengths(value, 0, 100)]
}

const CustomActionFormModal = ({ showing, handleClose }) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.customActions.form);
  const saving = useSelector((state) => state.customActions.saving);
  const errors = form?.errors || {};
  const type = form?.type || 'new';

  const handleSave = () => {
    const submissionErrors = validate(form, customActionValidators);

    if(Object.keys(submissionErrors).length > 0) {
      dispatch(addErrorsToCustomActionForm(submissionErrors));
    } else {
      dispatch(saveCustomAction());
    }
  }

  const handleRemove = () => {
    dispatch(removeCustomAction(form.id));
  }

  const handleFieldUpdate = (field, value) => {
    dispatch(updateCustomActionField(field, value));
  }

  const handleFileAdditions = (files) => {
    files = Array.from(files)
    const newDocuments = form.action_document_logs || [];

    files.forEach((file, index) => {
      newDocuments.push({ new: true, id: Date.now() + index, action_document: { file }});
    });

    dispatch(updateCustomActionField('action_document_logs', newDocuments));
  }

  const handleFileRemove = (fileIndex) => {
    const newDocuments = form.action_document_logs;
    if(newDocuments[fileIndex].new) {
      newDocuments.splice(fileIndex, 1);
    } else {
      newDocuments[fileIndex]._destroy = true;
    }

    dispatch(updateCustomActionField('action_document_logs', newDocuments));
  }

  return (
    <Modal show={showing} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{ type === 'edit' ? 'Edit Custom Action' : 'Add new Custom Action' }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { form && 
          <Row className="actionForm">
            {
              type ==='new' ?
              <CustomActionNewFormFields handleFieldUpdate={handleFieldUpdate} form={form} errors={errors} />
              :
              <CustomActionEditFormFields handleFieldUpdate={handleFieldUpdate} form={form} errors={errors} />
            }
            <Col md={5}>
              <FormGroup controlId="assigned_to" validationState={errors.assigned_to ? 'error' : null}>
                <ControlLabel>Action owner</ControlLabel>
                <FormControl
                  type="text"
                  name="assigned_to"
                  value={form.assigned_to}
                  onChange={(e) => handleFieldUpdate(e.target.name, e.target.value)} />
                { errors.assigned_to && <HelpBlock>{errors.assigned_to}</HelpBlock> }
              </FormGroup>
              <FormGroup controlId="deadline" validationState={errors.deadline ? 'error' : null}>
                <ControlLabel>Completion target date</ControlLabel>
                <DatePicker
                  selected={form.deadline}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleFieldUpdate('deadline', date)}
                  className='form-control' />
                { errors.deadline && <HelpBlock>{errors.deadline}</HelpBlock> }
              </FormGroup>
              {
                type === 'edit' &&
                <FormGroup controlId="completion_percentage" validationState={errors.completion_percentage ? 'error' : null}>
                  <ControlLabel>Completion percentage</ControlLabel>
                  <InputGroup>
                    <FormControl
                      type="number"
                      name="completion_percentage"
                      value={form.completion_percentage}
                      onChange={(e) => handleFieldUpdate(e.target.name, e.target.value)}
                      min={0}
                      max={100} />
                    <InputGroup.Addon>%</InputGroup.Addon>
                  </InputGroup>
                  { errors.completion_percentage && <HelpBlock>{errors.completion_percentage}</HelpBlock> }
                </FormGroup>
              }
            </Col>
            <Col md={12}>
              <SupportingEvidence documents={form.action_document_logs} handleFileAdditions={handleFileAdditions} handleFileRemove={handleFileRemove}  />
            </Col>
          </Row>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} className="pull-left">Close</Button>
        { type === 'edit' && (
          <Button onClick={handleRemove} bsStyle="danger" disabled={saving}>
            <Icon icon="trash" /> Remove
          </Button>
        )}
        <Button onClick={handleSave} bsStyle="primary" disabled={saving}>
          <SaveButtonContent type={type} />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const SaveButtonContent = ({ type }) => {
  if(type === 'edit') {
    return <><Icon icon="save" /> Update action</>;
  } else if(type === 'new') {
    return <><Icon icon="plus" /> Add custom action</>;
  }
}

const CustomActionNewFormFields = ({ handleFieldUpdate, form, errors }) =>
  <Col md={7}>
    <FormGroup controlId="description" validationState={errors.description ? 'error' : null}>
      <ControlLabel>Action description</ControlLabel>
      <FormControl
        componentClass="textarea"
        type="textarea"
        name="description"
        value={form.description}
        rows="6"
        maxLength="200"
        onChange={(e) => handleFieldUpdate(e.target.name, e.target.value)} />
      <HelpBlock>Maximum 200 characters{ errors.description && ` - ${errors.description}` }</HelpBlock>
    </FormGroup>
  </Col>

const CustomActionEditFormFields = ({ handleFieldUpdate, form, errors }) => {
  const [editingDescription, setEditingDescription] = useState(false);
  const [initialDescription, setInitialDescription] = useState('');

  const handleStartDescriptionEditing = () => {
    setInitialDescription(form.description);
    setEditingDescription(true);
  }

  const handleCancelDescriptionEditing = () => {
    handleFieldUpdate('description', initialDescription)
    setEditingDescription(false);
  }

  return (
    <>
      <Col xs={12}>
      {
          editingDescription ?
            <FormGroup controlId="description" validationState={errors.description ? 'error' : null}>
              <Row>
                <Col xs={8}>
                  <ControlLabel>Action description</ControlLabel>
                </Col>
                <Col xs={4} className="text-right">
                  <Button bsStyle="default" bsSize="xs" className="editButton" onClick={handleCancelDescriptionEditing}>Cancel editing</Button>
                </Col>
              </Row>
              <FormControl
                componentClass="textarea"
                type="textarea"
                name="description"
                value={form.description}
                rows="4"
                maxLength="200"
                onChange={(e) => handleFieldUpdate(e.target.name, e.target.value)} />
              <HelpBlock>Maximum 200 characters{ errors.description && ` - ${errors.description}` }</HelpBlock>
            </FormGroup>
          :
            <>
              <p className="lead">{ form.description }</p>
              <Button bsStyle="default" bsSize="xs" className="editButton" onClick={handleStartDescriptionEditing}>Edit action description</Button>
            </>
        }
      </Col>
      <Col md={7}>
        <FormGroup controlId="progress" validationState={errors.progress ? 'error' : null}>
          <ControlLabel>Progress update</ControlLabel>
          <FormControl
            componentClass="textarea"
            type="textarea"
            name="progress"
            value={form.progress}
            rows="4"
            maxLength="400"
            onChange={(e) => handleFieldUpdate(e.target.name, e.target.value)} />
          <HelpBlock>Maximum 400 characters{ errors.progress && ` - ${errors.progress}` }</HelpBlock>
        </FormGroup>
      </Col>
    </>
  )
}

export default CustomActionFormModal;
