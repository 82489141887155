import React from 'react';

import { formatDateString } from 'services/date_helpers';

import DeadlineIcon from './DeadlineIcon';

const ActionDetails = ({ action }) =>
  <div className="actionDetails">
    <div className="description">
      { action.description }
    </div>
    <div className="owner pull-left">Owner: { action.assigned_to }</div>
    <div className="deadline pull-right"><DeadlineIcon deadline={action.deadline} /> Deadline: { formatDateString(action.deadline) }</div>
  </div>

export default ActionDetails;