// Calculating as business time, which has 8 hours in a day
const seconds_in_hour = 3600
export const seconds_in_day = seconds_in_hour * 8
export const seconds_in_week = seconds_in_day * 5

export function seconds_to_business_time(seconds) {
  if(seconds / seconds_in_week >= 1) {
    return seconds_to_weeks(seconds);
  } else if(seconds / seconds_in_day >= 1) {
    return seconds_to_days(seconds);
  } else {
    return seconds_to_hours(seconds);
  }
}

function seconds_to_weeks(seconds) {
  const remaining_days = seconds_to_days(seconds % seconds_in_week)
  const weeks = Math.floor(seconds / seconds_in_week)
  return `${weeks > 0 ? `${weeks} ${weeks > 1 ? 'weeks' : 'week'}` : '' } ${remaining_days}`
}

function seconds_to_days(seconds) {
  const days = Math.floor(seconds / seconds_in_day)
  return `${days > 0 ? `${days} ${days > 1 ? 'days' : 'day'}` : '' }`
}

function seconds_to_hours(seconds) {
  return `${Math.floor(seconds / seconds_in_hour)} hours`
}

export function formatDate(date) {
  return date.toLocaleDateString('en-GB');
}

export function formatDateString(dateString) {
  return formatDate(new Date(dateString))
}