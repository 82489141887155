import { combineReducers, createStore, applyMiddleware } from 'redux';

import { TopicsReducer, FormReducer } from 'reducers/action_plan/initial';
import { saveTopicGoalMiddleware } from 'middleware/action_plan/initial';

import { validateTopic } from 'services/action_plan_helper';

export default function(initialData) {
  let initialTopicGoal = initialData.topics[0];
  let selectedIndex = 0;

  for(let i = 0; i < initialData.topics.length; i++) {
    if(!validateTopic(initialData.topics[i])) {
      initialTopicGoal = initialData.topics[i];
      selectedIndex = i;
      break;
    }
  }

  const initialSelectedOption = initialTopicGoal.assessment_topic.assessment_options.find((option) => option.id === initialTopicGoal.assessment_option_id);
  const noAction = initialSelectedOption ? initialSelectedOption.score === initialTopicGoal.on_site_score : false;

  const defaultData = {
    topics: initialData.topics,
    form: {
      currentTopicGoal: initialTopicGoal,
      selectedOption: initialSelectedOption,
      selectedIndex,
      actions: initialTopicGoal.active_topic_goal_actions,
      noActionReason: initialTopicGoal.no_action_reason,
      noAction: noAction,
      changed: false,
      saving: false,
    }
  }

  return createStore(
    combineReducers({ topics: TopicsReducer, form: FormReducer }),
    defaultData,
    applyMiddleware(saveTopicGoalMiddleware)
  );
}