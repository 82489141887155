export function mutateNewDocuments(action_document) {
  return action_document.map(action_document_log => {
    action_document_log = { ...action_document_log };
    action_document_log.action_document = { ...action_document_log.action_document }

    if(action_document_log.new) {
      return {
        action_document_attributes: {
          file: action_document_log.action_document.file
        }
      };
    } else {
      return {
        id: action_document_log.id,
        _destroy: action_document_log._destroy
      };
    }
  });
}