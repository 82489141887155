import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import I18n from 'locales/index.js'

import Icon from 'components/Icon';
import ActionPlanCategoriesSection from './actions/ActionPlanCategoriesSection';
import CustomActionsSection from './custom_actions/CustomActionsSection';
import ChecklistActionsSection from './checklist_actions/ChecklistActionsSection';
import ActionPlanFeedback from './ActionPlanFeedback';

const ActionPlanIndexScreen = ({ readyForChecklistActions=false, lastActionPlan }) => {
  const editable = useSelector((state) => state.form.editingEnabled);
  const scrollY = useSelector((state) => state.form.scrollY);
  const [currentContent, setCurrentContent] = useState('main');
  const exportUrl = useMemo(() => {
    let url = window.location.pathname.split('/');
    if(url[url.length - 1] === "progress") {
      url.pop();
    }
    url = `${url.join('/')}.xlsx`;
    return url;
  }, [])

  useEffect(() => {
    if(scrollY) {
      window.scrollTo(0, scrollY);
    }
  }, []);

  let content = undefined;

  if(currentContent === 'main') {
    content = (
      <>
        <ActionPlanCategoriesSection />
        <CustomActionsSection />
      </>
    )
  } else if(currentContent === 'checklist') {
    content = <ChecklistActionsSection />
  }

  return (
    <div className="actionPlanIndexScreen">
      <a
        href={window.programmeHelper.linkHelper('/')}
        className="btn btn-default margin-left-10 pull-right">
        <Icon icon="arrow-left"/> Return to dashboard
      </a>
      <a
        href={window.programmeHelper.linkHelper(exportUrl)}
        className="btn btn-default margin-left-10 pull-right">
        <Icon icon="file-excel"/> Export to Excel
      </a>
      <h1>Action Plan Overview</h1>
      { editable ?
        <p className="lead">
          Please update the progress for the actions inside each topic, paying
          particular attention to any marked as due soon or overdue.
        </p>
        :
        <p className="lead">
          {
            lastActionPlan && lastActionPlan.submitted_for_assessment_at ?
            `This action plan was submitted on ${moment(lastActionPlan.submitted_for_assessment_at).format("MMMM Do YYYY")}.`
            :
            "This is the progress view of your current action plan. You can edit your action plan from your dashboard."
          }
        </p>
      }
      
      {
        lastActionPlan && lastActionPlan.feedback && 
          <ActionPlanFeedback feedback={lastActionPlan.feedback} />
      }

      { readyForChecklistActions && <ActionPlanOverviewTabs selected={currentContent} handleTabClick={setCurrentContent} /> }
      { content }

      { editable &&
        <div className="actionPlanIndexFooter">
          <h1>Submit action plan</h1>
          <p>When you are ready for your IA to review your changes to the action plan, you can submit your Action Plan below.</p>
          <a
            href={window.programmeHelper.linkHelper('/customer/action_plans/submit_for_review')}
            className="btn btn-primary"
            data-confirm={`Are you sure you wish to submit your action plan ${ readyForChecklistActions ? `and your checklist ` : '' }to your industrial advisor?`}
            data-confirm-title="Submit your action plan?">
            <Icon icon="check"/> Submit for review
          </a>
          <a
            href={window.programmeHelper.linkHelper('/')}
            className="btn btn-default margin-left-10">
            <Icon icon="arrow-left"/> Return to dashboard
          </a>
        </div>
      }
    </div>
  );
}

const ActionPlanOverviewTabs = ({ selected, handleTabClick }) =>
  <div className="actionPlanOverviewTabs">
    <ul>
      <li>
        <button
          onClick={() => handleTabClick('main')}
          disabled={selected === 'main'}
          className={selected === 'main' ? 'selected' : ''}>
            Actions
          </button>
      </li>
      <li>
        <button
          onClick={() => handleTabClick('checklist')}
          disabled={selected === 'checklist'}
          className={selected === 'checklist' ? 'selected' : ''}>
            { I18n.t('customer.action_plan.checklist_actions.name') }
          </button>
      </li>
    </ul>
  </div>

export default ActionPlanIndexScreen;