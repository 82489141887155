import React, { useState, useEffect, useRef } from 'react';
import { Provider } from 'react-redux';

import App from 'components/App';
import ActionPlanInitalForm from './ActionPlanInitialForm';
import ActionPlanProgress from './ActionPlanProgress';

import buildStore from 'stores/action_plan/initial';

import 'styles/action_plan/initial.scss';
import 'styles/action_plan/shared.scss';

const ActionPlanInitialPage = ({ action_plan_topics }) => {
  const storeRef = useRef(buildStore({ topics: action_plan_topics }));

  return (
    <App>
      <Provider store={storeRef.current}>
        <div className="actionPlanInitialPage">
          <ActionPlanInitalForm />
          <ActionPlanProgress />
        </div>
      </Provider>
    </App>
  );
}

export default ActionPlanInitialPage;