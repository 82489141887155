export const selectAssessmentGoal = (topicId, optionId) => ({
  type: 'SELECT_ASSESSMENT_GOAL',
  topicId,
  optionId
});

export const addAction = () => ({
  type: 'ADD_ACTION'
});

export const updateAction = (actionIndex, field, new_value) => ({
  type: 'UPDATE_ACTION',
  payload: {
    actionIndex,
    field,
    value: new_value
  }
});

export const removeAction = (actionIndex) => ({
  type: 'REMOVE_ACTION',
  actionIndex
});

export const changeTopic = (topic, index) => ({
  type: 'CHANGE_TOPIC',
  topic,
  index
});

export const saveTopic = () => ({
  type: 'SAVE_TOPIC'
});

export const savingForm = () => ({
  type: 'SAVING_FORM'
});

export const savingComplete = () => ({
  type: 'SAVING_COMPLETE'
});

export const updateNoActionText = (noActionReason) => ({
  type: 'UPDATE_NO_ACTION_REASON',
  noActionReason
});