import { combineReducers, createStore, applyMiddleware } from 'redux';

import { TopicsReducer, FormReducer, ScoresReducer, CustomActionsReducer, ChecklistItemsReducer } from 'reducers/action_plan/progress';
import { saveTopicGoalMiddleware, saveCustomActionMiddleware, removeCustomActionMiddleware, saveChecklistActionMiddleware, removeChecklistActionMiddleware, handleScrollPosition } from 'middleware/action_plan/progress';

export default function(initialData) {
  const topics = initialData.topics;
  let categories = {};

  Object.keys(initialData.categories).forEach((categoryId) => {
    if(Object.keys(topics).includes(categoryId)) {
      categories[categoryId] = initialData.categories[categoryId];
    }
  });

  const defaultData = {
    topics: initialData.topics,
    categories: initialData.categories,
    scores: initialData.scores,
    customActions: {
      customActions: initialData.custom_actions,
    },
    form: {
      currentTopicGoal: undefined,
      editingEnabled: initialData.editingEnabled
    },
    actionPlan: initialData.action_plan,
    checklistItems: {
      items: initialData.checklist_items
    }
  }

  return createStore(
    combineReducers({
      topics: TopicsReducer,
      form: FormReducer,
      categories: (state={}) => state,
      scores: ScoresReducer,
      customActions: CustomActionsReducer,
      actionPlan: (state={}) => state,
      checklistItems: ChecklistItemsReducer
    }),
    defaultData,
    applyMiddleware(
      saveTopicGoalMiddleware,
      saveCustomActionMiddleware,
      removeCustomActionMiddleware,
      saveChecklistActionMiddleware,
      removeChecklistActionMiddleware,
      handleScrollPosition,
    )
  );
}