import React from 'react';
import { Button } from 'react-bootstrap';

const ModalContentCompanies = ({ companies }) =>
  <>
    <table className="modal-body table table-striped companies-modal-loaded">
      <thead>
        <tr>
          <th colSpan="2">
            Number of companies: { companies.length }
            <p className='text-muted'>Please note some companies may be counted more than once in the report.</p>
          </th>
        </tr>
      </thead>
      <tbody>
        { companies.map(company =>
          <tr key={`company_modal_${company.id}`}>
            <td className="col-xs-10">{ company.name }</td>
            <td className="col-xs-2">
              <Button title={`Go to ${company.name}'s company record`} href={window.programmeHelper.linkHelper(`/staff/company_records/${company.id}`)} target="_blank" rel="noopener">
                <i className="fas fa-search margin-right-5"></i>View Record
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </>

export default ModalContentCompanies;