import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { changeTopic } from 'actions/action_plan/progress';

import Icon from 'components/Icon';
import TopicStatus, { calculateProgressPercent } from './TopicStatus';
import CategoryScores from './CategoryScores';

const ActionPlanCategoriesSection = () => {
  const dispatch = useDispatch();

  const editable = useSelector((state) => state.form.editingEnabled);
  const categories = useSelector((state) => state.categories);
  const topics = useSelector((state) => state.topics);

  const handleTopicActionClick = (topic, index) => {
    dispatch(changeTopic(topic, index));
    window.scrollTo(0, 0);
  }

  return (
    <>
      { Object.values(categories).map((category) =>
        topics[category.id] && topics[category.id].length > 0 &&
          <div className={`category category_${category.id}`} key={`category_${category.id}`}>
            <div className="header">
              <h1>{ category.name }</h1>
              <CategoryScores categoryId={category.id} />
            </div>
            <table className="table margin-bottom-0 topicsTable">
              <thead>
                <tr>
                  <th className="topic">Topic</th>
                  <th className="on-site">On-site</th>
                  <th className="targetScore">Target score</th>
                  <th className="currentProgress">Progress</th>
                  <th className="actionsStatus">Status</th>
                  <th className="actions"></th>
                </tr>
              </thead>
              <tbody>
                { topics[category.id].map((topic, index) =>
                  <tr className={`topic topic_${topic.assessment_topic.id}`} key={`topic_${topic.assessment_topic.id}`}>
                    <td>{ topic.assessment_topic.name }</td>
                    <td>{ topic.on_site_score }</td>
                    <td>{ topic.score }</td>
                    <td>{ topic.no_action ? '-' : `${calculateProgressPercent(topic.active_topic_goal_actions)}%` }</td>
                    <td><TopicStatus topic={topic} /></td>
                    <td className="text-right">
                      <TopicActionButton topic={topic} handleOnClick={() => handleTopicActionClick(topic, index)} editable={editable} />
                    </td>
                  </tr>
                ) }
              </tbody>
            </table>
          </div>
      ) }
    </>
  )
}

const TopicActionButton = ({ topic, handleOnClick, editable }) => {
  if (editable) {
    return (
      <Button bsStyle={topic.updated ? 'success' : 'default'} bsSize="small" onClick={handleOnClick}>
        <Icon icon="edit" className="margin-right-5" />
        Update Progress
      </Button>
    );
  } else {
    return (
      <Button bsStyle="default" bsSize="small" onClick={handleOnClick}>
        <Icon icon="eye" className="margin-right-5" />
        View Progress
      </Button>
    );
  }
}

export default ActionPlanCategoriesSection;